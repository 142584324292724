<template>
    <v-form>
        <v-row>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Penalty" type="number" :field="'penalty'" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Penalty Note" type="text" :field="'penalty_note'" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IInventory } from '@app/land-hold/inventory/models/Inventory';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IInventory>,
        required: true,
    },
});

const update = useInventoryRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);
</script>
