<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Analytics' }]" />
        </template>
        <template #content>
            <v-container fluid>
                <v-row>
                    <v-col cols="12" xl="4">
                        <v-card>
                            <v-card-text>
                                <InventoryVolumeStatsChart />
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" xl="4">
                        <v-card>
                            <v-card-text>
                                <InventoryBufferStatsChart />
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </AdminPanelTemplate>
</template>
