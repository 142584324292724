<template>
    <DataTable
        title="Countries"
        :headers="Country.headers()"
        :model-class="Country"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Country',
            updateDialogBtnText: 'Update Country',
            deleteDialogBtnText: 'Delete Country',
        }"
        :config="useConfig().country"
    >
        <template #create-action-dialog="{ item }">
            <CreateCountryDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateCountryDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteCountryDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Country, type ICountry } from '../../models/Country';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ICountry>>();
</script>
