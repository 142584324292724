<template>
    <DataTable
        title="Inventory Queue"
        :headers="InventoryQueue.headers()"
        :model-class="InventoryQueue"
        :items="page.props.data"
        :crud-config="{
            showCreateAction: false,
            showUpdateAction: false,
            showDeleteAction: false,
            showViewAction: false,
        }"
        :config="useConfig().inventoryQueueView"
    >
    </DataTable>
</template>

<script setup lang="ts">
import { InventoryQueue } from '@app/land-hold/inventory/models/InventoryQueue';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';
import type { IInventoryView } from '@app/land-hold/inventory/models/InventoryView';

const page = usePage<PaginatedResponse<IInventoryView>>();
</script>
