<template>
    <DataTable
        title="Clients"
        :headers="Client.headers()"
        :items="page.props.data"
        :model-class="Client"
        :crud-config="{
            createDialogBtnText: 'Create Client',
            updateDialogBtnText: 'Update Client',
            deleteDialogBtnText: 'Delete Client',
        }"
        :config="useConfig().client"
    >
        <template #create-action-dialog="{ item }">
            <CreateClientDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateClientDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteClientDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type IClient, Client } from '@app/purchase/client/models/Client';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<IClient>>();
</script>
