<template>
    <DataTable
        title="Blocks"
        :headers="Transaction.routes().blocksIndex.datatableHeaders()"
        :model-class="Block"
        :items="page.props.data"
        :crud-config="{
            showCreateAction: false,
            showDeleteAction: false,
            showUpdateAction: false,
            showCustomAction: false,
            showViewAction: false,
        }"
        :breadcrumb-items="[{ title: 'Transaction', to: useRoute().build(Transaction.routes().index) }, { title: 'Transaction Details', to: useTransactionRepository().query.show(page.props.transaction.data.id).endpoint }, { title: 'Blocks' }]"
        :search-endpoint="() => useRoute().build(Transaction.routes().blocksIndex, { id: page.props.transaction.data.id })"
        :config="useConfig().block"
    >
    </DataTable>
</template>

<script setup lang="ts">
import { Block } from '@app/purchase/block/models/Block';
import { Transaction } from '@app/purchase/transaction/models/Transaction';
import { useRoute } from '@shared/composables/useRoute';
import { useConfig } from '@shared/composables/useConfig';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';

const page = Transaction.routes().blocksIndex.page;
</script>
