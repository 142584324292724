import { Chart as ChartJs, Legend, Title, Tooltip } from 'chart.js';

ChartJs.register(Legend, Title, Tooltip);

ChartJs.defaults.plugins.title.display = true;
ChartJs.defaults.plugins.title.padding = 16;
ChartJs.defaults.plugins.title.font = {
    size: 14,
};
ChartJs.defaults.plugins.legend.position = 'bottom';
ChartJs.defaults.font.family = "'Roboto Mono', 'sans-serif'";
ChartJs.defaults.font.size = 12;
ChartJs.defaults.font.lineHeight = 1.2;
ChartJs.defaults.layout.padding = 16;
ChartJs.defaults.color = '#657b83';
ChartJs.defaults.borderColor = 'rgba(147, 161, 161, 0.25)';
ChartJs.defaults.plugins.tooltip.mode = 'index';
ChartJs.defaults.responsive = true;
