<template>
    <DataTable
        title="Transactions"
        :headers="Transaction.headers()"
        :model-class="Transaction"
        :items="page.props.data"
        :crud-config="{
            showCreateAction: true,
            showUpdateAction: false,
            showDeleteAction: true,
            showViewAction: false,
            createDialogBtnText: 'Create Transaction',
            showCustomAction: true,
            customDialogTitle: 'Fulfill Transaction',
            customDialogBtnText: 'Fulfill',
            customDialogBtnIcon: 'mdi-upload',
            customDialogBtnDisabled: (item) => Transaction.create(item as unknown as Transaction).can().fulfill,
        }"
        :config="useConfig().transaction"
    >
        <template #top-bar>
            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn @click="useDownloadCsv('transactions', Transaction.csvHeaders())">Download CSV</v-btn>
                </v-col>
            </v-row>
        </template>
        <template #create-action-dialog="{ item }">
            <CreateTransactionDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteTransactionDialog :item />
        </template>
        <template #custom-action-dialog="{ item }">
            <FulfillTransactionDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Transaction } from '@app/purchase/transaction/models/Transaction';
import { useConfig } from '@shared/composables/useConfig';
import { useDownloadCsv } from '@shared/composables/useDownloadCsv';

const page = Transaction.routes().index.page;
</script>
