<template>
    <p>Are you sure you want to delete this Landuse Factor?</p>
</template>

<script setup lang="ts">
import { type ILandUseFactor } from '@app/land-hold/land-use-factor/models/LandUseFactor';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useLandUseFactorRepository } from '@app/land-hold/land-use-factor/repositories/useLandUseFactorRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ILandUseFactor>,
        required: true,
    },
});

const destroy = useLandUseFactorRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
