<template>
    <DataTable
        title="Partitions"
        :headers="Partition.routes().index.datatableHeaders()"
        :model-class="Partition"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Partition',
            updateDialogBtnText: 'Update Partition',
            deleteDialogBtnText: 'Delete Partition',
            showCreateAction: false,
            showUpdateAction: false,
            showDeleteAction: false,
        }"
        :config="useConfig().partition"
    >
    </DataTable>
</template>

<script setup lang="ts">
import { Partition } from '../../models/Partition';
import { useConfig } from '@shared/composables/useConfig';

const page = Partition.routes().index.page;
</script>
