import type { SubmitableFn, SubmitableComponentRef } from '@shared/types/GenericActionDialog';

export function useInjectSubmitForm(submitable: SubmitableFn) {
    const submitableComponentRef = inject<SubmitableComponentRef>('submitableComponentRef');
    onMounted(() => {
        if (submitableComponentRef) {
            submitableComponentRef.value = submitable;
        }
    });
}
