import { useProvideSubmitForm } from '@shared/composables/action-dialog/useProvideSubmitForm';

export function useSubmitForm() {
    const submitableComponentRef = useProvideSubmitForm();
    const processing = ref(false);
    const wasSuccessful = ref(false);

    function confirm(closeMethod?: () => void) {
        submitableComponentRef.value({
            onStart: () => {
                processing.value = true;
                wasSuccessful.value = false;
            },
            onSuccess: () => {
                closeMethod?.();
                wasSuccessful.value = true;
            },
            onFinish: () => {
                processing.value = false;
            },
        });
    }

    return {
        confirm,
        processing,
        wasSuccessful,
    };
}
