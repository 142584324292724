<template>
    <v-form>
        <v-row>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Landuse Code" :field="'landuse_code'" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Description" :field="'description'" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Image URL" :field="'image_url'" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { type ILandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useLandUseTypeRepository } from '@app/land-hold/land-use-type/repositories/useLandUseTypeRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ILandUseType>,
        required: true,
    },
});

const store = useLandUseTypeRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
</script>
