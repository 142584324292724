import { Model } from '@shared/models/Model';
import { type IndexInventoryTypeIncludes, routes } from '@app/land-hold/inventory-type/routes/routes';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IInventoryType extends IModel {
    name: string;
    buffer_percentage: string;
}

export class InventoryType extends Model implements IInventoryType {
    public name!: string;
    public buffer_percentage!: string;

    public constructor(o: IInventoryType) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof IInventoryType)[] {
        return [];
    }

    public static override headers(): DataTableHeaders<IndexInventoryTypeIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Inventory Type Name', key: 'name' },
            { title: 'Buffer Percentage', key: 'buffer_percentage' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
