import { Head, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { type App, type DefineComponent } from 'vue';
import { useUtility } from '@shared/composables/useUtility';

const { isUndefined, isNull } = useUtility();

export function registerComponents(app: App) {
    app.component('InertiaHead', Head);
    app.component('InertiaLink', Link);
}

// https://neoighodaro.com/posts/6-reorganising-inertia-js-pages
// https://github.com/inertiajs/inertia-laravel/issues/247
// https://github.com/inertiajs/inertia/issues/188#issuecomment-675091658_
// https://github.com/inertiajs/inertia-laravel/issues/92
export async function resolveComponent(name: string): Promise<DefineComponent> {
    const paths = import.meta.glob<Promise<DefineComponent>>(['/resources/**/*.page.vue', '/resources/**/*.layout.vue'], { eager: true });
    // Resolve layout components
    const defaultLayout = await resolvePageComponent('/resources/shared/components/layouts/Default.layout.vue', paths);
    const registryLayout = await resolvePageComponent('/resources/shared/components/layouts/Registry.layout.vue', paths);
    const registryPublicLayout = await resolvePageComponent('/resources/shared/components/layouts/Registry.public.layout.vue', paths);

    const appDirectoryPublicPagePath = `/resources/app/${name}.public.page.vue`;
    const appDirectoryPagePath = `/resources/app/${name}.page.vue`;
    const sharedDirectoryPagePath = `/resources/shared/components/pages/${name}.page.vue`;
    const page = await resolvePageComponent(appDirectoryPagePath, paths)
        .then((component) => component)
        .catch(async () => {
            return await resolvePageComponent(appDirectoryPublicPagePath, paths)
                .then((component) => component)
                .catch(async () => {
                    return await resolvePageComponent(sharedDirectoryPagePath, paths)
                        .then((component) => component)
                        .catch(async () => {
                            return await resolvePageComponent(sharedDirectoryPagePath, paths)
                                .then((component) => component)
                                .catch(async () => {
                                    return await resolvePageComponent('/resources/shared/components/pages/404.page.vue', paths);
                                });
                        });
                });
        });

    // Set page layout
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isUndefined(page['default'].layout)) {
        if (name.includes('.public')) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            page['default'].layout = [defaultLayout['default'], registryPublicLayout['default']];
        } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            page['default'].layout = [defaultLayout['default'], registryLayout['default']];
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (isNull(page['default'].layout)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        page['default'].layout = defaultLayout['default'];
    }

    return page;
}
