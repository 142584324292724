<template>
    <DataTable
        title="Inventories"
        :headers="Inventory.routes().index.datatableHeaders()"
        :model-class="Inventory"
        :items="page.props.data"
        :crud-config="{
            showCreateAction: false,
            showUpdateAction: true,
            showDeleteAction: true,
            showViewAction: false,
            updateDialogBtnText: 'Update Inventory',
        }"
        :config="useConfig().inventory"
    >
        <template #update-action-dialog="{ item }">
            <UpdateInventoryDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteInventoryDialog :item />
        </template>
        <template #top-bar>
            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn @click="useDownloadCsv('inventories', Inventory.csvHeaders())">Download CSV</v-btn>
                </v-col>
            </v-row>
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { useConfig } from '@shared/composables/useConfig';
import { useDownloadCsv } from '@shared/composables/useDownloadCsv';

const page = Inventory.routes().index.page;
</script>
