import type { GeneralConfig } from '@shared/types/Config';

export const propertyConfig: GeneralConfig = {
    sortFieldMap: {
        'inventory_type.data.name': 'inventory_types:inventory_type_id|inventory_types.name',
        'owner.data.name': 'owners:owner_id|owners.name',
        'steward.data.steward_code': 'stewards:steward_id|stewards.steward_code',
        'state.data._3_letter_code': 'states:state_id|states._3_letter_code',
    },
};
