import { Block, type IBlock } from '../models/Block';
import { type ITransaction } from '@app/purchase/transaction/models/Transaction';
import type { IInventory } from '@app/land-hold/inventory/models/Inventory';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';

export enum AvailableIncludes {
    Transaction = 'transaction',
    Inventory = 'inventory',
}

export interface TransactionInclude {
    transaction: SingleObjectInclude<ITransaction>;
}
export interface InventoryInclude {
    inventory: SingleObjectInclude<IInventory>;
}
export type AllBlockIncludes = IBlock & TransactionInclude & InventoryInclude;
export type IndexBlockIncludes = IBlock & TransactionInclude & InventoryInclude;
export type ShowBlockIncludes = IndexBlockIncludes;

export function routes(): {
    index: IndexRoute<'blocks.index', IndexBlockIncludes>;
    show: ShowRoute<'blocks.show', ShowBlockIncludes>;
    destroy: DestroyRoute<'blocks.destroy'>;
    create: CreateRoute<'blocks.create'>;
    store: StoreRoute<'blocks.store'>;
    edit: EditRoute<'blocks.edit'>;
    update: UpdateRoute<'blocks.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'blocks.index',
            params: { include: [AvailableIncludes.Transaction, AvailableIncludes.Inventory], orderBy: 'created_at', sortedBy: 'desc' },
            datatableHeaders: () => Block.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'blocks.show', params: { include: [AvailableIncludes.Transaction, AvailableIncludes.Inventory] }, page: usePage() },
        destroy: { method: 'delete', name: 'blocks.destroy', page: usePage() },
        create: { method: 'get', name: 'blocks.create', page: usePage() },
        store: { method: 'post', name: 'blocks.store', page: usePage() },
        edit: { method: 'get', name: 'blocks.edit', page: usePage() },
        update: { method: 'patch', name: 'blocks.update', page: usePage() },
    };
}
