import type { IProperty } from '@app/land-hold/property/models/Property';
import type { ILandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IPartition, Partition } from '@app/land-hold/partition/models/Partition';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';

export enum AvailableIncludes {
    LanduseType = 'landuse_type',
    Property = 'property',
}
export interface PropertyInclude {
    property: SingleObjectInclude<IProperty>;
}
export interface LandUseTypeInclude {
    landuse_type: SingleObjectInclude<ILandUseType>;
}
export type IndexPartitionIncludes = IPartition & PropertyInclude & LandUseTypeInclude;
type ShowPartitionIncludes = IndexPartitionIncludes;

export function routes(): {
    index: IndexRoute<'partitions.index', IndexPartitionIncludes>;
    show: ShowRoute<'partitions.show', ShowPartitionIncludes>;
    destroy: DestroyRoute<'partitions.destroy'>;
    create: CreateRoute<'partitions.create'>;
    store: StoreRoute<'partitions.store'>;
    edit: EditRoute<'partitions.edit'>;
    update: UpdateRoute<'partitions.update'>;
} {
    return {
        index: { method: 'get', name: 'partitions.index', params: { include: [AvailableIncludes.LanduseType, AvailableIncludes.Property], orderBy: 'created_at', sortedBy: 'desc' }, datatableHeaders: () => Partition.headers(), page: usePage() },
        show: { method: 'get', name: 'partitions.show', page: usePage() },
        destroy: { method: 'delete', name: 'partitions.destroy', page: usePage() },
        create: { method: 'get', name: 'partitions.create', page: usePage() },
        store: { method: 'post', name: 'partitions.store', page: usePage() },
        edit: { method: 'get', name: 'partitions.edit', page: usePage() },
        update: { method: 'patch', name: 'partitions.update', page: usePage() },
    };
}
