import { Model } from '@shared/models/Model';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { useStewardRepository } from '@app/land-hold/steward/repositories/useStewardRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface ISteward extends IModel {
    steward_code: string;
    name: string;
}

export class Steward extends Model implements ISteward {
    public steward_code!: string;
    public name!: string;

    public constructor(o: ISteward) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<ISteward> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name', link: (item: ISteward) => useStewardRepository().query.show(item.id).endpoint },
            { title: 'Steward code', key: 'steward_code' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'stewards.index', ISteward>;
        show: ShowRoute<'stewards.show'>;
        destroy: DestroyRoute<'stewards.destroy'>;
        create: CreateRoute<'stewards.create'>;
        store: StoreRoute<'stewards.store'>;
        edit: EditRoute<'stewards.edit'>;
        update: UpdateRoute<'stewards.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'stewards.index',
                params: {
                    orderBy: 'name',
                    sortedBy: 'desc',
                },
                datatableHeaders: () => Steward.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'stewards.show', page: usePage() },
            destroy: { method: 'delete', name: 'stewards.destroy', page: usePage() },
            create: { method: 'get', name: 'stewards.create', page: usePage() },
            store: { method: 'post', name: 'stewards.store', page: usePage() },
            edit: { method: 'get', name: 'stewards.edit', page: usePage() },
            update: { method: 'patch', name: 'stewards.update', page: usePage() },
        };
    }
}
