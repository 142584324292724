<template>
    <v-text-field v-model="value" :error-messages="errors" :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" autocomplete="current-password" :label="label" :type="showPassword ? 'text' : 'password'" @click:append-inner="showPassword = !showPassword" />
</template>

<script setup lang="ts">
defineProps({
    label: {
        type: String,
        default: 'Password',
    },
    errors: {
        type: String,
        default: null,
    },
});

const showPassword = ref(false);

const value = defineModel<string | null>('value');
</script>
