<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="3-letter Code" :field="'_3_letter_code'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.country_id" v-model:form="form" :items="countries" :field-name="'country_id'" :loading="fetchingCountries" label="Country" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IState } from '@app/location/state/models/State';
import { Country, type ICountry } from '@app/location/country/models/Country';
import { useFetchInertiaPageJson } from '@shared/composables/useFetchInertiaPageJson';
import { useRoute } from '@shared/composables/useRoute';
import { useStateRepository } from '@app/location/state/repositories/useStateRepository';
import type { ListResponse } from '@shared/types/ApiResponse';

const props = defineProps({
    item: {
        type: Object as PropType<IState>,
        required: true,
    },
});

const update = useStateRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);

const countries: Ref<ICountry[]> = ref([]);
const fetchingCountries = ref(false);
function fetchCountries() {
    fetchingCountries.value = true;
    useFetchInertiaPageJson<ListResponse<ICountry>>(useRoute().build(Country.routes().index), 'appSection@country::IndexCountriesPage', [], {
        params: {
            limit: 0,
        },
    })
        .then(({ data: page }) => {
            countries.value = (page.props as ListResponse<ICountry>).data;
        })
        .catch((err: unknown) => {
            console.error(err);
        })
        .finally(() => {
            fetchingCountries.value = false;
        });
}

onBeforeMount(() => {
    fetchCountries();
});
</script>
