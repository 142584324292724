<template>
    <v-form>
        <v-row>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.landuse_type_id" v-model:form="form" :field-name="'landuse_type_id'" :items="landuseTypes" label="Landuse Type" :loading="fetchingLandUseTypes" />
            </v-col>
            <v-col cols="6">
                <Autocomplete v-model:selected-items="form.year" v-model:form="form" :field-name="'year'" :items="years" label="Year" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" :min="0" type="number" label="Landuse Factor" :field="'fixed_landuse_factor'" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { type ILandUseFactor } from '@app/land-hold/land-use-factor/models/LandUseFactor';
import type { ILandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import { useUtility } from '@shared/composables/useUtility';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useLandUseFactorRepository } from '@app/land-hold/land-use-factor/repositories/useLandUseFactorRepository';
import type { ListResponse } from '@shared/types/ApiResponse';

const years = useUtility().getYearRange();
const currentYear = useUtility().currentYear();

const props = defineProps({
    item: {
        type: Object as PropType<ILandUseFactor>,
        required: true,
    },
});

const store = useLandUseFactorRepository().command.store({ ...props.item, year: currentYear });
const form = store.form;
useInjectSubmitForm(store.execute);

const landuseTypes: Ref<ILandUseType[]> = ref([]);
const fetchingLandUseTypes = ref(false);
function fetchLandUseTypes() {
    router.reload({
        only: ['landuseTypes'],
        onStart() {
            fetchingLandUseTypes.value = true;
        },
        onSuccess(page) {
            landuseTypes.value = (page.props['landuseTypes'] as ListResponse<ILandUseType>).data;
        },
        onFinish() {
            fetchingLandUseTypes.value = false;
        },
    });
}

onBeforeMount(() => {
    fetchLandUseTypes();
});
</script>
