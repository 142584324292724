import { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import type { Page, PageProps } from '@inertiajs/core';

export async function useFetchInertiaPageJson<T extends PageProps>(url: string, component: string, partialData: string[] = [], config?: AxiosRequestConfig): Promise<AxiosResponse<Page<T>>> {
    const { version } = usePage();

    return window.axios.get(url, {
        ...config,
        ...{
            headers: {
                ...config?.headers,
                ...{
                    'X-Inertia': true,
                    'x-inertia-partial-component': component,
                    'X-Inertia-Partial-Data': partialData.join(','),
                    'X-Inertia-Version': version ?? '',
                },
            },
        },
    });
}
