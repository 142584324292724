<template>
    <p>Are you sure you want to delete this Landuse Type?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { type ILandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useLandUseTypeRepository } from '@app/land-hold/land-use-type/repositories/useLandUseTypeRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ILandUseType>,
        required: true,
    },
});

const destroy = useLandUseTypeRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
