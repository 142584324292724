import type { IInventoryView } from '@app/land-hold/inventory/models/InventoryView';
import type { IInventory } from '@app/land-hold/inventory/models/Inventory';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IProperty, Property } from '@app/land-hold/property/models/Property';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import type { IndexPropertyIncludes, ShowPropertyIncludes } from '@app/land-hold/property/routes/routes';
import { AvailableIncludes as PropertyAvailableIncludes } from '@app/land-hold/property/routes/routes';
import type { IInventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import type { InventoryVolumeStat } from '@app/land-hold/inventory/contracts/InventoryVolumeStat';
import type { InventoryBufferPenaltyStat } from '@app/land-hold/inventory/contracts/InventoryBufferPenaltyStat';
import type { SingleObjectInclude, ListResponse } from '@shared/types/ApiResponse';
import type { IOwner } from '@app/land-hold/owner/models/Owner.ts';

enum AvailableIncludes {
    InventoryView = 'inventory_view',
    Property = 'property',
    Owner = 'owner',
    InventoryType = 'inventory_type',
}
interface InventoryViewInclude {
    inventory_view: SingleObjectInclude<IInventoryView>;
}
interface PropertyInclude {
    property: SingleObjectInclude<IProperty>;
}
interface OwnerInclude {
    owner: SingleObjectInclude<IOwner>;
}
interface InventoryTypeInclude {
    inventory_type: SingleObjectInclude<IInventoryType>;
}
export type AllInventoryIncludes = IInventory & InventoryViewInclude & PropertyInclude & OwnerInclude & InventoryTypeInclude;
export type IndexInventoryIncludes = AllInventoryIncludes;
type ShowInventoryIncludes = IndexInventoryIncludes;
export type IndexInventoryPropertyIncludes = IndexPropertyIncludes;

export function routes(): {
    index: IndexRoute<'inventories.index', IndexInventoryIncludes>;
    show: ShowRoute<'inventories.show', ShowInventoryIncludes>;
    destroy: DestroyRoute<'inventories.destroy'>;
    create: CreateRoute<'inventories.create'>;
    store: StoreRoute<'inventories.store'>;
    edit: EditRoute<'inventories.edit'>;
    update: UpdateRoute<'inventories.update'>;
    management: {
        properties: {
            index: IndexRoute<'inventories.management.properties.index', IndexInventoryPropertyIncludes>;
            show: ShowRoute<'inventories.management.properties.show', ShowPropertyIncludes>;
        };
    };
    stats: {
        volume: ShowRoute<'inventories.stats.volume', InventoryVolumeStat, ListResponse<InventoryVolumeStat>>;
        buffer: ShowRoute<'inventories.stats.buffer', InventoryBufferPenaltyStat, ListResponse<InventoryBufferPenaltyStat>>;
    };
} {
    return {
        index: { method: 'get', name: 'inventories.index', params: { include: [AvailableIncludes.InventoryType, AvailableIncludes.InventoryView, AvailableIncludes.Property, AvailableIncludes.Owner] }, datatableHeaders: () => Inventory.headers(), page: usePage() },
        show: { method: 'get', name: 'inventories.show', params: { include: [AvailableIncludes.InventoryType, AvailableIncludes.InventoryView, AvailableIncludes.Property, AvailableIncludes.Owner] }, page: usePage() },
        destroy: { method: 'delete', name: 'inventories.destroy', page: usePage() },
        create: { method: 'get', name: 'inventories.create', page: usePage() },
        store: { method: 'post', name: 'inventories.store', page: usePage() },
        edit: { method: 'get', name: 'inventories.edit', page: usePage() },
        update: { method: 'patch', name: 'inventories.update', page: usePage() },
        management: {
            properties: {
                index: { method: 'get', name: 'inventories.management.properties.index', params: { include: [PropertyAvailableIncludes.InventoryType, PropertyAvailableIncludes.Owner, PropertyAvailableIncludes.State, PropertyAvailableIncludes.Steward, PropertyAvailableIncludes.InventoriesView] }, datatableHeaders: () => Property.manageInventoryPageHeaders(), page: usePage() },
                show: { method: 'get', name: 'inventories.management.properties.show', params: { include: [PropertyAvailableIncludes.Owner, PropertyAvailableIncludes.Steward, PropertyAvailableIncludes.State] }, page: usePage() },
            },
        },
        stats: {
            volume: { method: 'get', name: 'inventories.stats.volume', page: usePage() },
            buffer: { method: 'get', name: 'inventories.stats.buffer', page: usePage() },
        },
    };
}
