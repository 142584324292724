import { Model } from '@shared/models/Model';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';

import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface ILandUseType extends IModel {
    airtable_id: string;
    landuse_code: string;
    name: string;
    description: string;
    image_url: string;
}

export class LandUseType extends Model implements ILandUseType {
    airtable_id!: string;
    landuse_code!: string;
    name!: string;
    description!: string;
    image_url!: string;

    public constructor(o: ILandUseType) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<ILandUseType> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Landuse Code', key: 'landuse_code' },
            { title: 'Name', key: 'name' },
            { title: 'Description', key: 'description' },
            { title: 'Image URL', key: 'image_url' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'landuse-types.index', ILandUseType>;
        show: ShowRoute<'landuse-types.show'>;
        destroy: DestroyRoute<'landuse-types.destroy'>;
        create: CreateRoute<'landuse-types.create'>;
        store: StoreRoute<'landuse-types.store'>;
        edit: EditRoute<'landuse-types.edit'>;
        update: UpdateRoute<'landuse-types.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'landuse-types.index',
                datatableHeaders: () => LandUseType.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'landuse-types.show', page: usePage() },
            destroy: { method: 'delete', name: 'landuse-types.destroy', page: usePage() },
            create: { method: 'get', name: 'landuse-types.create', page: usePage() },
            store: { method: 'post', name: 'landuse-types.store', page: usePage() },
            edit: { method: 'get', name: 'landuse-types.edit', page: usePage() },
            update: { method: 'patch', name: 'landuse-types.update', page: usePage() },
        };
    }
}
