<template>
    <DataTable
        :crud-config="{
            showCreateAction: true,
            showUpdateAction: false,
            showDeleteAction: false,
            showViewAction: false,
            createDialogBtnText: 'Generate Inventory',
        }"
        :headers="Inventory.routes().management.properties.index.datatableHeaders()"
        :items="page.props.data"
        :model-class="Property"
        :search-endpoint="() => useRoute().build(Inventory.routes().management.properties.index)"
        show-expand
        show-select
        :item-selectable="(item: IProperty) => Property.create(item).can().store"
        title="Property Inventories"
        :config="useConfig().property"
    >
        <template #create-action-dialog="{ items }">
            <GenerateInventoryDialog :items />
        </template>

        <template #filter-dialog>
            <ManageInventoryFilter />
        </template>

        <!--        TODO: Fix this! What if there is no data? Then we get an error.-->
        <!--        (typeof page.props.data)[0] is a hack to get the type of the first element in the array.-->
        <template #expanded-row="{ item }: { item: (typeof page.props.data)[0] }">
            <v-row class="inventories-table" dense>
                <v-col cols="auto">
                    <DataTable
                        :crud-config="{
                            showCreateAction: false,
                            showUpdateAction: false,
                            showDeleteAction: false,
                            showViewAction: false,
                        }"
                        :headers="InventoryView.manageInventoryPageHeaders()"
                        :items="item.inventories_view.data"
                        :model-class="InventoryView"
                        :show-breadcrumbs="false"
                        :show-pagination="false"
                        :show-toolbar="false"
                        theme="dataTableSubTableLight"
                        :config="useConfig().inventoryView"
                    >
                    </DataTable>
                </v-col>
            </v-row>
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type IProperty, Property } from '@app/land-hold/property/models/Property';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { InventoryView } from '../../models/InventoryView';
import { useRoute } from '@shared/composables/useRoute';
import { useConfig } from '@shared/composables/useConfig';

const page = Inventory.routes().management.properties.index.page;
</script>

<style lang="scss" scoped>
.inventories-table {
    margin: 0 0 0 -16px;
}
</style>
