<template>
    <v-autocomplete v-model="selectedItems" :rules="props.rules" :error-messages="transformError(form?.errors[props.fieldName])" :disabled="props.disabled" :hide-details="props.hideDetails" :items="props.items" :label="props.label" :loading="props.loading" :multiple="props.multiple" :variant="props.variant" :readonly="readonly" chips :closable-chips="props.closableChips" :hide-selected="props.hideSelected" :item-title="props.itemTitle" :item-value="props.itemValue" persistent-hint :return-object="props.returnObject" />
</template>

<script setup lang="ts" generic="T extends NonNullable<unknown>, U extends PrecognitionFormData">
import { useUtility } from '@shared/composables/useUtility';
import type { PrecognitionForm, PrecognitionFormData } from '@shared/types/Form';
import type { PropType } from 'vue';

const { transformError } = useUtility();
type ValidationResult = string | boolean;
type ValidationRule = ValidationResult | ((value: string) => ValidationResult);

interface Props {
    items: T[];
    label?: string;
    itemTitle?: string;
    itemValue?: keyof T;
    // TODO:
    //  this should not be required because some autocompletes just want to show some data without validation,
    //  like for chart page components
    fieldName: keyof U;
    loading?: boolean;
    multiple?: boolean;
    variant?: 'filled' | 'underlined' | 'outlined' | 'plain' | 'solo' | 'solo-inverted' | 'solo-filled';
    disabled?: boolean;
    readonly?: boolean;
    closableChips?: boolean;
    hideDetails?: boolean;
    hideSelected?: boolean;
    returnObject?: boolean;
    rules?: ValidationRule[];
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    itemTitle: 'name',
    itemValue: 'id' as keyof T,
    loading: false,
    multiple: false,
    variant: 'outlined',
    disabled: false,
    readonly: false,
    closableChips: false,
    hideDetails: false,
    hideSelected: false,
    returnObject: false,
    rules: () => [] as ValidationRule[],
});

const selectedItems = defineModel<object | string | number | number[] | null>('selectedItems', {
    required: true,
});

const form = defineModel<PrecognitionForm<U>>('form', {
    type: Object as PropType<PrecognitionForm<U>>,
    required: false,
});

watch(selectedItems, () => {
    if (useUtility().isString(props.fieldName) && form.value) {
        form.value.validate(props.fieldName);
    }
});
</script>
