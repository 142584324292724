import { useDate as vuetifyUseDate } from 'vuetify';
import { useUtility } from '@shared/composables/useUtility';
import DayjsUtils from '@date-io/dayjs';
import dayjs from 'dayjs';
import type { DatePickerDate } from '@shared/types/DatePickerDate';

interface ExtendedDateUtils {
    toISO8601(date: DatePickerDate): string | null;
}
export function useDate() {
    const dateInstance = vuetifyUseDate() as DayjsUtils & ExtendedDateUtils;

    const { isNull, isEmptyString } = useUtility();
    function toISO8601(date: DatePickerDate): string | null {
        if (isNull(date) || isEmptyString(date) || !dateInstance.isValid(date)) {
            return null;
        }

        if (dayjs.isDayjs(date)) {
            return date.format('YYYY-MM-DD');
        }

        return dateInstance.parseISO(date).format('YYYY-MM-DD');
    }

    if (!Object.prototype.hasOwnProperty.call(dateInstance, 'toISO8601')) {
        Object.defineProperty(dateInstance, 'toISO8601', {
            value: toISO8601,
            writable: false,
        });
    }

    return dateInstance;
}
