<template>
    <v-row justify="space-between">
        <v-col cols="auto">
            <v-toolbar-title class="ps-2">{{ title }}</v-toolbar-title>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <slot name="actions" />
        </v-col>
        <v-col cols="4">
            <v-text-field v-if="showSearch" v-model="search" clearable label="Search" :append-inner-icon="loading ? 'mdi-loading' : 'mdi-magnify'" single-line variant="outlined" hide-details :loading="loading" />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import type { SearchQueryParameter } from '@core/types/shared-props';

defineProps({
    title: {
        type: String,
        default: () => '',
    },
    loading: {
        type: Boolean,
        default: false,
    },
    showSearch: {
        type: Boolean,
        default: true,
    },
});

const search = defineModel<SearchQueryParameter>('search');
</script>
