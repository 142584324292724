<template>
    <DataTable
        title="Landuse Types"
        :headers="LandUseType.headers()"
        :model-class="LandUseType"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Landuse Type',
            updateDialogBtnText: 'Update Landuse Type',
            deleteDialogBtnText: 'Delete Landuse Type',
        }"
        :config="useConfig().landUseType"
    >
        <template #create-action-dialog="{ item }">
            <CreateLandUseTypeDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateLandUseTypeDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteLandUseTypeDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type ILandUseType, LandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ILandUseType>>();
</script>
