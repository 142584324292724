<template>
    <p>Are you sure you want to delete this State?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IState } from '@app/location/state/models/State';
import { useStateRepository } from '@app/location/state/repositories/useStateRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IState>,
        required: true,
    },
});

const destroy = useStateRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
