import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import { type ILandUseFactor, LandUseFactor } from '@app/land-hold/land-use-factor/models/LandUseFactor';
import type { FormOptions } from '@shared/types/Form';

export function useLandUseFactorRepository<T extends ILandUseFactor = ILandUseFactor>() {
    const command = () => {
        const store = (data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                LandUseFactor.routes().store,
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<T>(data).precognition(
                {
                    url: useRoute().build(LandUseFactor.routes().update, {
                        id,
                    }),
                    method: LandUseFactor.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) => usePayload<T>(data)).submit(options);
            }

            return { form, execute };
        };

        const destroy = <U extends { id: string }>(id: string, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm<U>({ id } as U).precognition(
                {
                    url: useRoute().build(LandUseFactor.routes().destroy, {
                        id,
                    }),
                    method: LandUseFactor.routes().destroy.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.submit(options);
            }

            return { form, execute };
        };

        return {
            store,
            update,
            destroy,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(LandUseFactor.routes().show, { id }) };
        };

        return {
            show,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
