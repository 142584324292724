import type { FilterComponentRef, FilterFn } from '@shared/types/GenericActionDialog';

export function useProvideFilter(): FilterComponentRef {
    const filterComponentRef: FilterComponentRef = ref<FilterFn>(() => {
        throw new Error('filter method not implemented');
    });
    provide<FilterComponentRef>('filterComponentRef', filterComponentRef);

    return filterComponentRef;
}
