<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <Autocomplete v-model:selected-items="includeYearSelectedFilters" hide-details field-name="year" :return-object="false" :items="yearRange" label="Year" multiple />
                </v-col>
                <v-col>
                    <Autocomplete v-model:selected-items="includeInventoryTypeNewFilters" hide-details item-title="name" field-name="''" :items="inventoryTypes" label="Type" multiple :loading="fetchingInventoryTypes" />
                </v-col>
                <v-col cols="auto">
                    <v-btn color="primary" :loading="fetchingInventories || fetchingInventoryTypes" @click="fetchInventoryStats">Apply Filters</v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12">
            <BarChart v-if="chartData" id="inventory-stats-chart" :data="chartData" :options="options" />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import type { InventoryVolumeStat } from '@app/land-hold/inventory/contracts/InventoryVolumeStat';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { useRoute } from '@shared/composables/useRoute';
import { useUtility } from '@shared/composables/useUtility';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import type { ListResponse } from '@shared/types/ApiResponse';

const options = reactive<ChartOptions<'bar'>>({
    plugins: {
        title: {
            text: 'Inventory Volume Stats',
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    indexAxis: 'y',
}) as ChartOptions<'bar'>;

const chartData = ref<ChartData<'bar'> | null>(null);
const { getYearRange } = useUtility();
const volumeStats: Ref<InventoryVolumeStat[]> = ref([]);
const fetchingInventories = ref(false);
// TODO: this operation must be moved to the repository
const fetchInventoryStats = () => {
    fetchingInventories.value = true;
    window.axios
        .get<ListResponse<InventoryVolumeStat>>(useRoute().build(Inventory.routes().stats.volume), {
            params: {
                year: includeYearSelectedFilters.value,
                inventory_type: includeInventoryTypeNewFilters.value,
            },
        })
        .then((response) => {
            volumeStats.value = response.data.data;
            chartData.value = statsToChartData(volumeStats.value);
        })
        .catch((error: unknown) => {
            console.error(error);
        })
        .finally(() => {
            fetchingInventories.value = false;
        });
};

function statsToChartData(stats: InventoryVolumeStat[]): ChartData<'bar'> {
    return {
        labels: stats.map((stat) => stat.inventory_type_name),
        datasets: [
            {
                label: 'Allocated',
                stack: 'Stack 0',
                data: stats.map((stat) => stat.carbon_stock_allocated),
                backgroundColor: 'rgba(227,111,89, 1)',
                barPercentage: 0.4,
            },
            {
                label: 'Available',
                stack: 'Stack 0',
                data: stats.map((stat) => stat.carbon_stock_available),
                backgroundColor: 'rgba(105,170,116, 1)',
                barPercentage: 0.4,
            },
        ],
    } as ChartData<'bar'>;
}

const yearRange = getYearRange();
const includeYearSelectedFilters = ref([]);
const includeInventoryTypeNewFilters = ref([]);

const { data: inventoryTypes, processing: fetchingInventoryTypes } = useFetchListData<IInventoryType>(useRoute().build(InventoryType.routes().index), 'appSection@inventoryType::IndexInventoryTypesPage');

onBeforeMount(() => {
    fetchInventoryStats();
});
</script>

<style scoped lang="scss"></style>
