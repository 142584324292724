import type { CrudConfig } from '@shared/types/DataTable';

export function useCrudConfig() {
    function normalize(crudConfig?: CrudConfig): Required<CrudConfig> {
        crudConfig = crudConfig ?? {};
        return {
            ...{
                showCreateAction: true,
                createDialogTitle: crudConfig.createDialogTitle ?? crudConfig.createDialogBtnText ?? 'Create Item',
                createDialogBtnText: 'Create Item',
                showViewAction: true,
                viewDialogTitle: crudConfig.viewDialogTitle ?? crudConfig.viewDialogBtnText ?? 'View Item',
                viewDialogBtnText: 'View Item',
                showUpdateAction: true,
                updateDialogTitle: crudConfig.updateDialogTitle ?? crudConfig.updateDialogBtnText ?? 'Edit Item',
                updateDialogBtnText: 'Edit Item',
                showDeleteAction: true,
                deleteDialogTitle: crudConfig.deleteDialogTitle ?? crudConfig.deleteDialogBtnText ?? 'Delete Item',
                deleteDialogBtnText: 'Delete Item',
                showCustomAction: false,
                customDialogTitle: crudConfig.customDialogTitle ?? 'Custom Action',
                customDialogBtnText: 'Confirm',
                customDialogBtnIcon: 'mdi-check',
                customDialogBtnDisabled: () => false,
            },
            ...crudConfig,
        };
    }

    return {
        normalize,
    };
}
