<template>
    <DataTable
        title="Stewards"
        :headers="Steward.headers()"
        :model-class="Steward"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Steward',
            updateDialogBtnText: 'Update Steward',
            deleteDialogBtnText: 'Delete Steward',
        }"
        :config="useConfig().steward"
    >
        <template #create-action-dialog="{ item }">
            <CreateStewardDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateStewardDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteStewardDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type ISteward, Steward } from '../../models/Steward';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ISteward>>();
</script>
