import { type ThemeDefinition } from 'vuetify';
import colors from 'vuetify/util/colors';
import { defaultLight } from '@core/themes/DefaultLight';

export const dataTableSubTableLight: ThemeDefinition = {
    ...defaultLight,
    ...{
        dark: false,
        colors: {
            surface: colors.grey.lighten5,
        },
    },
};
