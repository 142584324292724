export {};

// https://www.typescriptlang.org/docs/handbook/declaration-files/templates/global-modifying-module-d-ts.html
Object.defineProperty(String.prototype, 'camelToPascalCase', {
    value(): string {
        return this.split('/')
            .map((part: string) => part.charAt(0).toUpperCase() + part.slice(1))
            .join('/');
    },
});

Object.defineProperty(String.prototype, 'pluralize', {
    value(): string {
        const isPluralAlready = this.endsWith('ies') || this.endsWith('es') || (!this.endsWith('us') && this.endsWith('s'));
        if (isPluralAlready) {
            return this;
        }
        if (this.endsWith('y') && !this.endsWith('ay')) {
            return `${this.substring(0, this.length - 1)}ies`;
        }
        return this.endsWith('us') ? `${this}es` : `${this}s`;
    },
});

declare global {
    interface String {
        camelToPascalCase(this: string): string;
        pluralize(this: string): string;
    }
}
