import type { ChipConfig, DotNotationOf } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import type { CrudOperations } from '@shared/types/ApiOperation';

export abstract class Model implements IModel {
    [key: string]: unknown;
    public object: string;
    public id: string;
    public operations?: CrudOperations | undefined;
    public created_at: string;
    public updated_at: string;

    /// Instance Methods
    protected constructor(o: IModel) {
        this.object = o.object;
        this.id = o.id;
        this.operations = o.operations;
        this.created_at = o.created_at;
        this.updated_at = o.updated_at;
    }

    public can(): {
        store: boolean;
        delete: boolean;
        update: boolean;
    } {
        return {
            store: this.operations?.data.find((operation) => operation.name === 'store')?.allowed ?? true,
            delete: this.operations?.data.find((operation) => operation.name === 'delete')?.allowed ?? true,
            update: this.operations?.data.find((operation) => operation.name === 'update')?.allowed ?? true,
        };
    }

    /// Static Methods
    public static routes() {
        throw new Error('Not implemented');
    }

    public static empty<T extends IModel>(this: { new: (o: T) => T; create: (o: T) => T }): T {
        return this.create({} as T);
    }

    public static create<T extends IModel, U extends Model>(this: new (o: T) => U, input: T): U;
    public static create<T extends IModel, U extends Model>(this: new (o: T) => U, input: T[]): U[];
    public static create<T extends IModel, U extends Model>(this: new (o: T) => U, input: T | T[]): U | U[] {
        if (Array.isArray(input)) {
            return input.map((i) => new this(i));
        }
        return new this(input);
    }

    public static headers() {
        throw new Error('Not implemented');
    }

    public static chips(): string[] {
        return [];
    }

    public static chipConfig(field: DotNotationOf<IModel>, item: IModel): ChipConfig {
        throw new Error(`No chip config for ${field} in ${item.object}`);
    }
}
