<template>
    <v-form>
        <v-row>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.inventory_type_id" v-model:form="form" :field-name="'inventory_type_id'" :items="inventoryTypes" :loading="fetchingInventoryTypes" label="Inventory Type" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useRoute } from '@shared/composables/useRoute';
import { type ITransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { useTransactionTypeRepository } from '@app/purchase/transaction-type/repositories/useTransactionTypeRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ITransactionType>,
        required: true,
    },
});

const store = useTransactionTypeRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);

const { data: inventoryTypes, processing: fetchingInventoryTypes } = useFetchListData<IInventoryType>(useRoute().build(InventoryType.routes().index), 'appSection@inventoryType::IndexInventoryTypesPage');
</script>
