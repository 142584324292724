import type { ApiatoQueryParameters, FilterQueryParameterPayload, SearchQueryParameter } from '@core/types/shared-props';
import { useUtility } from '@shared/composables/useUtility';
import { useRoute } from '@shared/composables/useRoute';
import type { SortBy, SortOrder, DataTableQuery } from '@shared/types/Vuetify';
import type { SortFieldMap } from '@shared/types/Config';

const { isEmptyString, isNull, isString, isEmptyArray, isNil } = useUtility();

function isEmptySearch(value: SearchQueryParameter): boolean {
    return isEmptyString(value) || isNull(value);
}

function applySearch(query: ApiatoQueryParameters, search: SearchQueryParameter): ApiatoQueryParameters {
    const modified = { ...query } as ApiatoQueryParameters;

    if (isEmptySearch(search)) {
        if (modified.search) {
            delete modified.search;
        }
    } else {
        if (modified.search !== search) {
            modified.search = search;
        }
    }

    return modified;
}

export function useInteractWithDataTable() {
    const firstLoad = ref(false);
    const processing = ref(false);

    function applyQuery(tableQuery: DataTableQuery, currentQuery: ApiatoQueryParameters, search: SearchQueryParameter, route: () => string, filters: FilterQueryParameterPayload, sortFieldMap: SortFieldMap): DataTableQuery {
        if (firstLoad.value) {
            firstLoad.value = false;
            initDataTableOptions(tableQuery, currentQuery);
        } else {
            const query = normalize(tableQuery, currentQuery, sortFieldMap);
            router.get(route(), useRoute().filters().merge(applySearch(query, search), filters), {
                onStart: () => {
                    processing.value = true;
                },
                preserveState: true,
                replace: true,
                onFinish: () => {
                    processing.value = false;
                },
            });
        }

        return tableQuery;
    }

    onBeforeMount(() => {
        firstLoad.value = true;
    });

    return {
        applyQuery,
        processing,
    };
}

function normalize(dataTableQuery: DataTableQuery, query: ApiatoQueryParameters, sortFieldMap: SortFieldMap): ApiatoQueryParameters {
    const normalized = { ...query };

    if (query.page && isString(query.page)) {
        normalized.page = query.page;
    }
    if (query.page !== dataTableQuery.page) {
        normalized.page = dataTableQuery.page;
    }

    if (query.limit && isString(query.limit)) {
        normalized.limit = query.limit;
    }
    if (query.limit !== dataTableQuery.itemsPerPage) {
        normalized.limit = dataTableQuery.itemsPerPage;
    }

    if (shouldApplySortOptions(dataTableQuery.sortBy)) {
        const mappedKeys = replaceSortField(dataTableQuery.sortBy, sortFieldMap);
        normalized.orderBy = mappedKeys.map((item: SortBy) => item.key).join(';');
        normalized.sortedBy = mappedKeys.map((item: SortBy) => item.order).join(';');
    }

    return normalized;
}

function initDataTableOptions(tableOptions: DataTableQuery, query: ApiatoQueryParameters): void {
    if (query.orderBy) {
        const orderBy = toOrderByArray(query);
        const sortedBy = toSortOrderArray(query);
        for (let i = 0; i < orderBy.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const key = orderBy[i]!;
            tableOptions.sortBy.push({
                key,
                order: sortedBy[i] ?? 'desc',
            });
        }
    }
}
function replaceSortField(sortArray: SortBy[], sortFieldMap: SortFieldMap): SortBy[] {
    return sortArray.map((sortItem) => {
        const newKey = sortFieldMap[sortItem.key];
        return {
            ...sortItem,
            key: newKey ?? sortItem.key,
        };
    });
}
function shouldApplySortOptions(sortBy: SortBy[]) {
    return !isEmptyArray(sortBy);
}
function toOrderByArray(query: ApiatoQueryParameters): string[] {
    return query.orderBy ? query.orderBy.split(';').filter((item) => !isNil(item) && !isEmptyString(item)) : [];
}
function toSortOrderArray(query: ApiatoQueryParameters): SortOrder[] {
    return (query.sortedBy ? query.sortedBy.split(';') : []).filter((item) => validateSortOrder(item));
}
function validateSortOrder(value: string): value is SortOrder {
    return value === 'asc' || value === 'desc';
}
