import type { GeneralConfig } from '@shared/types/Config';

export const inventoryConfig: GeneralConfig = {
    sortFieldMap: {
        'inventory_view.data.carbon_inventory_tco2e': 'vw_inventories:id,inventory_id|carbon_inventory_tco2e',
        'inventory_view.data.carbon_stock_tco2e': 'vw_inventories:id,inventory_id|carbon_stock_tco2e',
        'inventory_view.data.carbon_buffer_tco2e': 'vw_inventories:id,inventory_id|carbon_buffer_tco2e',
        'inventory_view.data.count_of_blocks': 'vw_inventories:id,inventory_id|count_of_blocks',
        'inventory_view.data.carbon_stock_allocated': 'vw_inventories:id,inventory_id|carbon_stock_allocated',
        'inventory_view.data.carbon_stock_available': 'vw_inventories:id,inventory_id|carbon_stock_available',
        'inventory_view.data.status': 'vw_inventories:id,inventory_id|status',
        'inventory_view.data.buffer_percentage': 'vw_inventories:id,inventory_id|buffer_percentage',
        // 'property.data.property_code': 'properties:property_id,id|property_code',
        priority: 'properties:property_id,id|priority',
    },
};
