import { type InertiaForm, useForm as useInertiaForm } from '@inertiajs/vue3';
import { useForm as useInertiaPrecognitionForm } from 'laravel-precognition-vue-inertia';
import { useRoute } from '@shared/composables/useRoute';
import type { ExtendedInertiaForm, InertiaFormData, FormOptions, PrecognitionForm, PrecognitionFormData, PrecognitionOriginalForm, Url } from '@shared/types/Form';

export function useForm<TForm extends InertiaFormData>(rememberKey: string, data: TForm | (() => TForm)): ExtendedInertiaForm<TForm>;
export function useForm<TForm extends InertiaFormData>(data: TForm | (() => TForm)): ExtendedInertiaForm<TForm>;
export function useForm<TForm extends InertiaFormData>(...args: [string | TForm | (() => TForm), (TForm | (() => TForm))?]): ExtendedInertiaForm<TForm> {
    let form: InertiaForm<TForm>;
    let data: TForm | (() => TForm);

    if (args.length === 2) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data = args[1] instanceof Function ? args[1]() : args[1]!;
        form = useInertiaForm<TForm>(args[0] as string, data);
    } else {
        data = args[0] instanceof Function ? args[0]() : (args[0] as TForm);
        form = useInertiaForm<TForm>(data);
    }

    const precognition = (url: Url, config?: FormOptions): PrecognitionForm<TForm> => {
        let precognitionForm: PrecognitionOriginalForm<TForm>;

        const overrideConfig: FormOptions = {
            preserveScroll: true,
            ...config,
        };

        if (useRoute().isRoute(url)) {
            precognitionForm = useInertiaPrecognitionForm<PrecognitionFormData>(url.method, useRoute().build(url), data as PrecognitionFormData, overrideConfig) as PrecognitionOriginalForm<TForm>;
        } else {
            precognitionForm = useInertiaPrecognitionForm<PrecognitionFormData>(url.method, url.url, data as PrecognitionFormData, overrideConfig) as PrecognitionOriginalForm<TForm>;
        }

        // configs
        precognitionForm.setValidationTimeout(500);

        // bound methods
        const inertiaSubmit = precognitionForm.submit.bind(precognitionForm);
        function submit(config?: FormOptions) {
            const defaultConfig: FormOptions = {
                ...config,
                ...overrideConfig,
            };

            if (useRoute().isRoute(url)) {
                inertiaSubmit(url.method, useRoute().build(url), defaultConfig);
            } else {
                inertiaSubmit(url.method, url.url, defaultConfig);
            }
        }

        Object.defineProperty(precognitionForm, 'submit', {
            value: submit,
            writable: true,
        });

        return precognitionForm as PrecognitionForm<TForm>;
    };

    Object.defineProperty(form, 'precognition', {
        value: precognition,
        writable: true,
    });

    return form as ExtendedInertiaForm<TForm>;
}
