import { route } from 'ziggy-js';

export function useAnalyticsRepository() {
    const query = () => {
        const show = () => {
            return { endpoint: route('home-page') };
        };

        return {
            show,
        };
    };

    return {
        query: query(),
    };
}
