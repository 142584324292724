import { useForm } from '@shared/composables/useForm';
import { route } from 'ziggy-js';

export function useAuthenticationRepository() {
    const command = () => {
        const logout = () => {
            router.post('logout');
        };

        interface LoginForm {
            email: string | null;
            password: string | null;
            remember: boolean;
        }

        const login = <T extends LoginForm>(data: T) => {
            const form = useForm<T>(data);
            function execute() {
                form.submit('post', route('login'), {
                    onFinish: () => {
                        form.reset('password');
                    },
                });
            }

            return { form, execute };
        };

        interface PartnerLoginForm {
            partner_id: string | null;
            access_token: string | null;
        }

        const partnerLogin = <T extends PartnerLoginForm>(data: T) => {
            const form = useForm<T>(data);
            function execute() {
                form.submit('post', route('partner.login'), {
                    onFinish: () => {
                        form.reset('access_token');
                        form.cancel();
                    },
                });
            }

            return { form, execute };
        };

        return {
            logout,
            login,
            partnerLogin,
        };
    };

    const query = () => {
        const show = () => {
            return { endpoint: route('home-page') };
        };

        return {
            show,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
