import { Model } from '@shared/models/Model';
import { type IndexPartitionIncludes, routes } from '../routes/routes';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IPartition extends IModel {
    airtable_id: string;
    partition_area_ha: string;
    landuse_type_id: string;
    airtable_landuse_type_id: string;
    property_id: string;
    airtable_property_id: string;
}

export class Partition extends Model implements IPartition {
    public airtable_id!: string;
    public partition_area_ha!: string;
    public landuse_type_id!: string;
    public airtable_landuse_type_id!: string;
    public property_id!: string;
    public airtable_property_id!: string;

    public constructor(o: IPartition) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IndexPartitionIncludes> {
        return [
            {
                title: 'Property Code',
                key: 'property.data.property_code',
                link: (item: IndexPartitionIncludes) => usePropertyRepository().query.show(item.property_id).endpoint,
                sortable: false,
            },
            { title: 'Area (ha)', key: 'partition_area_ha' },
            { title: 'Landuse Type', key: 'landuse_type.data.landuse_code', sortable: false },
            { title: 'Landuse Name', key: 'landuse_type.data.name' },
            { title: 'Property', key: 'property.data.property_name', link: (item: IndexPartitionIncludes) => usePropertyRepository().query.show(item.property_id).endpoint },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static propertyPartitionsHeaders(): DataTableHeaders<IndexPartitionIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 80, minWidth: '80', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Area (ha)', key: 'partition_area_ha' },
            { title: 'Landuse Type', key: 'landuse_type.data.landuse_code', sortable: false },
            { title: 'Landuse Name', key: 'landuse_type.data.name' },
            { title: 'Property', key: 'property.data.property_name', link: (item: IndexPartitionIncludes) => usePropertyRepository().query.show(item.property_id).endpoint },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
