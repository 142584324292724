<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Country Code" :field="'country_code'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type ICountry } from '@app/location/country/models/Country';
import { useCountryRepository } from '@app/location/country/repositories/useCountryRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ICountry>,
        required: true,
    },
});

const store = useCountryRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
</script>
