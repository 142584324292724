<template>
    <p>Are you sure you want to delete this Property?</p>
    <br />
    <p>[ {{ item.property_name }} ]</p>
</template>

<script setup lang="ts">
import { type IProperty } from '@app/land-hold/property/models/Property';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';

const props = defineProps({
    item: {
        type: Object as PropType<IProperty>,
        required: true,
    },
});

const destroy = usePropertyRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
