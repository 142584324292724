<template>
    <DataTable
        title="Blocks"
        :headers="Block.headers()"
        :model-class="Block"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Block',
            updateDialogBtnText: 'Update Block',
            deleteDialogBtnText: 'Delete Block',
            showCreateAction: false,
            showUpdateAction: false,
            showDeleteAction: false,
        }"
        :config="useConfig().block"
    >
    </DataTable>
</template>

<script setup lang="ts">
import { Block } from '@app/purchase/block/models/Block';
import { useConfig } from '@shared/composables/useConfig';

const page = Block.routes().index.page;
</script>
