import type { IndexRoute, ShowRoute } from '@shared/types/Route';
import { type IPublicTransaction, PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';

export const AvailableIncludes = {
    Blocks: 'blocks',
} as const;

export type AllTransactionIncludes = IPublicTransaction;
export type IndexPublicTransactionIncludes = IPublicTransaction;
export type ShowTransactionIncludes = IndexPublicTransactionIncludes;

export function routes(): {
    index: IndexRoute<'public.transactions.index', IndexPublicTransactionIncludes>;
    show: ShowRoute<'public.transactions.show', ShowTransactionIncludes>;
} {
    return {
        index: { method: 'get', name: 'public.transactions.index', datatableHeaders: () => PublicTransaction.headers(), page: usePage() },
        show: { method: 'get', name: 'public.transactions.show', params: { include: AvailableIncludes.Blocks }, page: usePage() },
    };
}
