import { type ThemeDefinition } from 'vuetify';
import colors from 'vuetify/util/colors';

export const defaultLight: ThemeDefinition = {
    dark: false,
    colors: {
        background: colors.grey.lighten3,
        primary: '#209b74',
    },
    variables: {
        sidebarSurface: '#0c343d',
    },
};
