<template>
    <DataTable
        title="Transaction Types"
        :headers="TransactionType.headers()"
        :model-class="TransactionType"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Transaction Type',
            updateDialogBtnText: 'Update Transaction Type',
            deleteDialogBtnText: 'Delete Transaction Type',
        }"
        :config="useConfig().transactionType"
    >
        <template #create-action-dialog="{ item }">
            <CreateTransactionTypeDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateTransactionTypeDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteTransactionTypeDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { useConfig } from '@shared/composables/useConfig';
import { type ITransactionType, TransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ITransactionType>>();
</script>
