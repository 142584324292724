<template>
    <DataTable
        title="States"
        :headers="State.headers()"
        :model-class="State"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create State',
            updateDialogBtnText: 'Update State',
            deleteDialogBtnText: 'Delete State',
        }"
        :config="useConfig().state"
    >
        <template #create-action-dialog="{ item }">
            <CreateStateDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateStateDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteStateDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type IState, State } from '@app/location/state/models/State';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<IState>>();
</script>
