import type { Breadcrumb } from '@core/types/shared-props';
import { useRoute } from '@shared/composables/useRoute';
import { useUtility } from '@shared/composables/useUtility';
import type { BreadcrumbItem } from '@shared/types/BreadcrumbItem';

const { isUndefined, isArray } = useUtility();
export function useBreadcrumb(routes: (string | string[])[]) {
    function breadcrumbs(breadcrumbs: Breadcrumb[]): BreadcrumbItem[] {
        return breadcrumbs.map((breadcrumb, index) => {
            const url = useRoute().toUrl(breadcrumb.url);
            const routeAtIndex = routes[index];
            if (!isUndefined(routeAtIndex)) {
                if (isArray(routeAtIndex)) {
                    const route = routeAtIndex.find((route) => {
                        return useRoute().toUrl(route).origin + useRoute().toUrl(route).pathname === breadcrumb.url;
                    });
                    if (!isUndefined(route)) {
                        useRoute()
                            .toUrl(route)
                            .searchParams.forEach((value, key) => {
                                url.searchParams.set(key, value);
                            });
                    }
                } else {
                    useRoute()
                        .toUrl(routeAtIndex)
                        .searchParams.forEach((value, key) => {
                            url.searchParams.set(key, value);
                        });
                }
            }
            if (breadcrumb.is_current_page) {
                return {
                    title: breadcrumb.title,
                };
            }
            return {
                title: breadcrumb.title,
                to: url.toString(),
            };
        });
    }

    return {
        breadcrumbs,
    };
}
