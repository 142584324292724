<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Blocks', to: useRoute().build(Block.routes().index) }, { title: 'Block Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.transaction.data.transaction_code" label="Transaction" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.inventory.data.inventory_code" label="Inventory" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.first_carbon_value" label="First Carbon Value" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.carbon_block_tco2e" label="Carbon (tCO2e)" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.last_carbon_value" label="Last Carbon Value" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.block_serial_code" label="Block Serial Code" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Block } from '@app/purchase/block/models/Block';
import { useRoute } from '@shared/composables/useRoute';

const page = Block.routes().show.page;
</script>
