<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" type="number" label="Carbon Amount" :field="'carbon_amount_tco2e'" />
            </v-col>
            <v-col cols="6">
                <DatePicker v-model:field="form.date_of_transaction" v-model:form="form" label="Transaction Date" :field-name="'date_of_transaction'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.steward_id" v-model:form="form" :field-name="'steward_id'" :items="stewards" label="Steward" :loading="fetchingStewards" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.company_id" v-model:form="form" :field-name="'company_id'" :items="clients" label="Client" :loading="fetchingClients" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.transaction_type_id" v-model:form="form" :field-name="'transaction_type_id'" :items="transactionTypes" label="Transaction Type" :loading="fetchingTransactionTypes" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.partner_id" v-model:form="form" :field-name="'partner_id'" :items="partners" label="Partner" :loading="fetchingPartners" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type ISteward, Steward } from '@app/land-hold/steward/models/Steward';
import { type ITransaction } from '@app/purchase/transaction/models/Transaction';
import { Client, type IClient } from '@app/purchase/client/models/Client';
import type { PropType } from 'vue';
import { useRoute } from '@shared/composables/useRoute';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { type ITransactionType, TransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';
import { type IPartner, Partner } from '@app/purchase/partner/models/Partner';

const props = defineProps({
    item: {
        type: Object as PropType<ITransaction>,
        required: true,
    },
});

const store = useTransactionRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
const { data: transactionTypes, processing: fetchingTransactionTypes } = useFetchListData<ITransactionType>(useRoute().build(TransactionType.routes().index), 'appSection@owner::IndexTransactionTypesPage');

const { data: stewards, processing: fetchingStewards } = useFetchListData<ISteward>(useRoute().build(Steward.routes().index), 'appSection@steward::IndexStewardsPage');
const { data: clients, processing: fetchingClients } = useFetchListData<IClient>(useRoute().build(Client.routes().index), 'appSection@client::IndexClientsPage');
const { data: partners, processing: fetchingPartners } = useFetchListData<IPartner>(useRoute().build(Partner.routes().index), 'appSection@partner::IndexPartnersPage');
</script>
