<template>
    <DataTable
        title="Landuse Factors"
        :headers="LandUseFactor.headers()"
        :model-class="LandUseFactor"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Landuse Factor',
            updateDialogBtnText: 'Update Landuse Factor',
            deleteDialogBtnText: 'Delete Landuse Factor',
        }"
        :config="useConfig().landUseFactor"
    >
        <template #create-action-dialog="{ item }">
            <CreateLandUseFactorDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateLandUseFactorDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteLandUseFactorDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type ILandUseFactor, LandUseFactor } from '@app/land-hold/land-use-factor/models/LandUseFactor';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<ILandUseFactor>>();
</script>
