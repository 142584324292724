import { Model } from '@shared/models/Model';
import { routes, type IndexBlockIncludes } from '@app/purchase/block/routes/routes';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';
import { useBlockRepository } from '@app/purchase/block/repositories/useBlockRepository';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IBlock extends IModel {
    airtable_id: string;
    airtable_transaction_id: string;
    transaction_id: string;
    airtable_inventory_id: string;
    inventory_id: string;
    first_carbon_value: string;
    carbon_block_tco2e: string;
    last_carbon_value: string;
    block_serial_code: string;
}

export class Block extends Model implements IBlock {
    airtable_id!: string;
    airtable_transaction_id!: string;
    transaction_id!: string;
    airtable_inventory_id!: string;
    inventory_id!: string;
    first_carbon_value!: string;
    carbon_block_tco2e!: string;
    last_carbon_value!: string;
    block_serial_code!: string;

    public constructor(o: IBlock) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IndexBlockIncludes> {
        return [
            { title: 'Block Serial Code', key: 'block_serial_code', link: (item) => useBlockRepository().query.show(item.id).endpoint },
            { title: 'Transaction Code', key: 'transaction.data.transaction_code', link: (item) => useTransactionRepository().query.show(item.transaction_id).endpoint },
            { title: 'Inventory Code', key: 'inventory.data.inventory_code', link: (item) => useInventoryRepository().query.show(item.inventory_id).endpoint },
            { title: 'First Carbon Value', key: 'first_carbon_value', align: 'center' },
            { title: 'Carbon (tCO2e)', key: 'carbon_block_tco2e', align: 'center' },
            { title: 'Last Carbon Value', key: 'last_carbon_value', align: 'center' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
