<template>
    <p>Are you sure you want to delete this Partition?</p>
</template>

<script setup lang="ts">
import { type IPartition } from '@app/land-hold/partition/models/Partition';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IPartition>,
        required: true,
    },
});

const destroyPartition = usePropertyRepository().command.destroyPartition(props.item.id);
useInjectSubmitForm(destroyPartition.execute);

const emit = defineEmits(['unmounted']);

onUnmounted(() => {
    emit('unmounted');
});
</script>
