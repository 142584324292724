import { Model } from '@shared/models/Model';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';

import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface ICountry extends IModel {
    country_code: string;
    name: string;
}

export class Country extends Model implements ICountry {
    public country_code!: string;
    public name!: string;

    public constructor(o: ICountry) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<ICountry> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Country Code', key: 'country_code' },
            { title: 'Name', key: 'name' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'countries.index', ICountry>;
        show: ShowRoute<'countries.show'>;
        destroy: DestroyRoute<'countries.destroy'>;
        create: CreateRoute<'countries.create'>;
        store: StoreRoute<'countries.store'>;
        edit: EditRoute<'countries.edit'>;
        update: UpdateRoute<'countries.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'countries.index',
                params: {
                    orderBy: 'name',
                    sortedBy: 'desc',
                },
                datatableHeaders: () => Country.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'countries.show', page: usePage() },
            destroy: { method: 'delete', name: 'countries.destroy', page: usePage() },
            create: { method: 'get', name: 'countries.create', page: usePage() },
            store: { method: 'post', name: 'countries.store', page: usePage() },
            edit: { method: 'get', name: 'countries.edit', page: usePage() },
            update: { method: 'patch', name: 'countries.update', page: usePage() },
        };
    }
}
