<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Profile' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-title> Account </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="4" xl="2">
                            <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
                        </v-col>
                        <v-col cols="12" sm="4" xl="2">
                            <ReadOnlyTextField :value="page.props.data.email" label="Email" :hide-details="false" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="form.processing || form.hasErrors" :loading="form.processing" variant="text" type="submit" @click="update.execute"> Update </v-btn>
                </v-card-actions>
            </v-card>
            <v-card class="ma-3">
                <v-card-title> Security </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="4" xl="2">
                            <PasswordField v-model:value="passwordForm.current_password" label="Current Password" :errors="transformError(passwordForm.errors.current_password)" />
                        </v-col>
                        <v-col cols="12" sm="4" xl="2">
                            <PasswordField v-model:value="passwordForm.new_password" label="New Password" :errors="transformError(passwordForm.errors.new_password)" />
                        </v-col>
                        <v-col cols="12" sm="4" xl="2">
                            <PasswordField v-model:value="passwordForm.new_password_confirmation" label="Confirm Password" :errors="transformError(passwordForm.errors.new_password_confirmation)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="passwordForm.processing" :loading="passwordForm.processing" variant="text" type="submit" @click="updatePassword.execute"> Update Password </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { User } from '../../models/User';
import { useUserRepository } from '@app/user/repositories/useUserRepository';
import { useUtility } from '@shared/composables/useUtility';

const { transformError } = useUtility();

const page = User.routes().show.page;

const update = useUserRepository().command.update(page.props.data.id, page.props.data);
const form = update.form;

const updatePassword = useUserRepository().command.updatePassword(page.props.data.id);
const passwordForm = updatePassword.form;
</script>
