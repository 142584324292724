import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { defaultLight } from '@core/themes/DefaultLight';
import { defaultDark } from '@core/themes/DefaultDark';
import { md3 } from 'vuetify/blueprints';
import '@mdi/font/css/materialdesignicons.css';
import { mdi, aliases } from 'vuetify/iconsets/mdi';
import { dataTableSubTableLight } from '@core/themes/DataTableSubTableLight';
import DayJsAdapter from '@date-io/dayjs';
import enGB from 'dayjs/locale/en-gb';

export default createVuetify({
    blueprint: md3,
    theme: {
        defaultTheme: 'defaultLight',
        themes: {
            defaultDark,
            defaultLight,
            dataTableSubTableLight,
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
        },
    },
    locale: {
        locale: 'en',
    },
    date: {
        adapter: DayJsAdapter,
        // https://vuetifyjs.com/en/components/date-pickers/#internationalization
        locale: {
            en: enGB,
        },
    },
    defaults: {
        VTextField: {
            color: 'primary',
            variant: 'outlined',
        },
        VRow: {
            align: 'center',
        },
    },
});
