import { Model } from '@shared/models/Model';
import type { ILandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import type { DotNotationOf, ChipConfig, DataTableHeaders } from '@shared/types/Vuetify';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IModel } from '@shared/types/Model';

export interface ILandUseFactor extends IModel {
    airtable_id: string;
    landuse_type_id: string;
    year: number;
    fixed_landuse_factor: string;
    landuse_type: SingleObjectInclude<ILandUseType>;
}

export class LandUseFactor extends Model implements ILandUseFactor {
    public airtable_id!: string;
    public landuse_type_id!: string;
    public year!: number;
    public fixed_landuse_factor!: string;
    public landuse_type!: SingleObjectInclude<ILandUseType>;

    public constructor(o: ILandUseFactor) {
        super(o);
        Object.assign(this, o);
    }

    public static override chips(): DotNotationOf<ILandUseFactor>[] {
        return ['year'];
    }

    public static override chipConfig(field: DotNotationOf<ILandUseFactor>, item: ILandUseFactor): ChipConfig {
        switch (field) {
            case 'year':
                return { size: 'small', color: '', text: item[field].toString() };
            default:
                return { size: 'small' };
        }
    }

    public static override headers(): DataTableHeaders<ILandUseFactor> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Landuse Type', key: 'landuse_type.data.landuse_code', sortable: false },
            { title: 'Landuse Name', key: 'landuse_type.data.name' },
            { title: 'Year', key: 'year' },
            { title: 'Landuse Factor', key: 'fixed_landuse_factor', align: 'center' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'landuse-factors.index', ILandUseFactor>;
        show: ShowRoute<'landuse-factors.show'>;
        destroy: DestroyRoute<'landuse-factors.destroy'>;
        create: CreateRoute<'landuse-factors.create'>;
        store: StoreRoute<'landuse-factors.store'>;
        edit: EditRoute<'landuse-factors.edit'>;
        update: UpdateRoute<'landuse-factors.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'landuse-factors.index',
                datatableHeaders: () => LandUseFactor.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'landuse-factors.show', page: usePage() },
            destroy: { method: 'delete', name: 'landuse-factors.destroy', page: usePage() },
            create: { method: 'get', name: 'landuse-factors.create', page: usePage() },
            store: { method: 'post', name: 'landuse-factors.store', page: usePage() },
            edit: { method: 'get', name: 'landuse-factors.edit', page: usePage() },
            update: { method: 'patch', name: 'landuse-factors.update', page: usePage() },
        };
    }
}
