<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="3-letter Code" :field="'_3_letter_code'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.country_id" v-model:form="form" :field-name="'country_id'" :items="countries" :loading="fetchingCountries" label="Country" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IState } from '@app/location/state/models/State';
import type { ICountry } from '@app/location/country/models/Country';
import { useStateRepository } from '@app/location/state/repositories/useStateRepository';
import type { ListResponse } from '@shared/types/ApiResponse';

const props = defineProps({
    item: {
        type: Object as PropType<IState>,
        required: true,
    },
});

const store = useStateRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);

const countries: Ref<ICountry[]> = ref([]);
const fetchingCountries = ref(false);
function fetchCountries() {
    router.reload({
        only: ['countries'],
        onStart() {
            fetchingCountries.value = true;
        },
        onSuccess(page) {
            countries.value = (page.props['countries'] as ListResponse<ICountry>).data;
        },
        onFinish() {
            fetchingCountries.value = false;
        },
    });
}

onBeforeMount(() => {
    fetchCountries();
});
</script>
