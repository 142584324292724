import type { Config } from 'ziggy-js';
import { route as routeZiggy } from 'ziggy-js';
import { useUtility } from '@shared/composables/useUtility';
import type { ApiatoQueryParameters, FilterQueryParameter, FilterQueryParameterPayload } from '@core/types/shared-props';
import type { Route, RouteParameters } from '@shared/types/Route';

const { isArray, isUndefined, isNil, isEmptyString } = useUtility();

export function useRoute() {
    function build<T extends Route>(route: T, params?: RouteParameters, absolute?: boolean, config?: Config): string {
        const defaultParams = route.params;

        if (route.params?.include && isArray(route.params.include)) {
            route.params.include = route.params.include.join(',');
        }

        if (params?.include && isArray(params.include)) {
            params.include = params.include.join(',');
        }

        // TODO:
        //  allow merging of route.config with config parameter and also allow using default route.absolute
        //  and let user override it by passing absolute parameter
        //  Right now we dont use route.config and route.absolute
        return routeZiggy(route.name, { ...defaultParams, ...params }, absolute, config);
    }

    function filters() {
        function current(): FilterQueryParameterPayload {
            return getCurrentFilterParams().map((key) => {
                return get(key);
            }) as FilterQueryParameterPayload;
        }

        function merge(query: ApiatoQueryParameters, filters: FilterQueryParameterPayload) {
            const filterQuery: Record<string, unknown> = {};
            filters.forEach((filter) => {
                if (isUndefined(filter)) return;
                if (isArray(filter)) {
                    if (filter[0]) {
                        if (filterQuery[filter[0].key]) {
                            (filterQuery[filter[0].key] as unknown[]).push(...filter.map((f) => f.value));
                        } else {
                            filterQuery[filter[0].key] = filter.map((f) => f.value);
                        }
                    }
                } else {
                    if (isNil(filter.value) || isEmptyString(filter.value)) return;
                    filterQuery[(filter as FilterQueryParameter).key] = (filter as FilterQueryParameter).value;
                }
            });

            return { ...filterQuery, ...query };
        }

        function get<T extends string = string>(key: T): FilterQueryParameter<T> | FilterQueryParameter<T>[] | undefined {
            const value = route().params[key];
            if (isUndefined(value)) {
                return undefined;
            }
            if (isArray(value)) {
                return value.map((item): FilterQueryParameter<T> => ({ key, value: item }));
            }

            return { key, value } as FilterQueryParameter<T>;
        }

        return {
            current,
            merge,
            get,
        };
    }

    function toUrl(url: string): URL {
        return new URL(url);
    }

    function isRoute(obj: unknown): obj is Route {
        if (!obj || typeof obj !== 'object') return false;
        return 'name' in obj && 'method' in obj;
    }

    return {
        build,
        filters,
        toUrl,
        isRoute,
    };
}

const KnownQueryParameter: (keyof ApiatoQueryParameters)[] = ['include', 'page', 'limit', 'orderBy', 'sortedBy', 'filter', 'search'];
function getCurrentFilterParams(): string[] {
    const params = route().params;
    return Object.keys(params).filter((key) => !KnownQueryParameter.includes(key as keyof ApiatoQueryParameters));
}
