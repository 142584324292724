<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <Autocomplete v-model:selected-items="form.year" v-model:form="form" :field-name="'year'" :items="years" label="Year" />
            </v-col>
        </v-row>

        <p v-if="!isEmptyArray(propertyIds)" class="mb-4">
            Do you want to generate inventory for the following properties for year <strong>{{ form.year }}</strong
            >?
        </p>

        <v-chip-group v-if="!isEmptyArray(propertyIds)" class="mb-4">
            <v-chip v-for="(property, j) in props.items" :key="j">{{ property['property_name'] }}</v-chip>
        </v-chip-group>

        <v-row v-if="isEmptyArray(propertyIds)">
            <v-col cols="12">
                <v-icon color="error" size="small" class="me-1">mdi-information</v-icon>
                <span class="error--text">Please select at least one property to generate inventory for.</span>
            </v-col>
        </v-row>

        <v-row v-if="!isEmptyArray(propertyIds)">
            <v-col cols="12">
                <v-icon color="blue" size="small" class="me-1">mdi-information</v-icon>
                <span class="ml-2">Inventory will only be generated for properties that do not have an inventory for the selected year.</span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useUtility } from '@shared/composables/useUtility';
import type { IProperty } from '@app/land-hold/property/models/Property';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';
import type { PrecognitionFormData } from '@shared/types/Form';

const { isEmptyArray, getYearRange, currentYear } = useUtility();
const thisYear = currentYear();
const years = getYearRange();
const props = defineProps({
    items: {
        type: Object as PropType<IProperty[]>,
        required: true,
    },
});

const propertyIds = computed<string[]>(() => props.items.map((item: IProperty) => item.id));

interface Payload extends PrecognitionFormData {
    property_ids: string[];
    year: number;
}

const generate = useInventoryRepository().command.generate<Payload>({
    property_ids: propertyIds.value,
    year: thisYear,
});
const form = generate.form;
useInjectSubmitForm(generate.execute);
</script>
