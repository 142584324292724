<template>
    <v-row>
        <v-col cols="12" sm="6">
            <Autocomplete v-model:selected-items="includeYearSelectedFilters" item-title="value" field-name="''" :items="includeYearsItems" label="Include Year" return-object multiple />
        </v-col>
        <v-col cols="12" sm="6">
            <Autocomplete v-model:selected-items="excludeYearSelectedFilters" item-title="value" field-name="''" :items="excludeYearsItems" label="Exclude Year" return-object multiple />
        </v-col>
        <v-col cols="12" sm="6">
            <Autocomplete v-model:selected-items="includeInventoryTypeNewFilters" item-title="name" field-name="''" item-value="id" :items="inventoryTypes" label="Include Inventory Type" multiple :loading="fetchingInventoryTypes" />
        </v-col>
        <v-col cols="12" sm="6">
            <Autocomplete v-model:selected-items="excludeInventoryTypeNewFilters" item-title="name" field-name="''" item-value="id" :items="inventoryTypes" label="Exclude Inventory Type" multiple :loading="fetchingInventoryTypes" />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { useUtility } from '@shared/composables/useUtility';
import type { FilterQueryParameter } from '@core/types/shared-props';
import { useInjectFilter } from '@shared/composables/action-dialog/useInjectFilter';
import { useRoute } from '@shared/composables/useRoute';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';

const { getYearRange } = useUtility();

type IncludeYearFilter = FilterQueryParameter<'includeYear'>[];
const includeYearsItems = computed<IncludeYearFilter>(() => getYearRange().map((year) => ({ key: 'includeYear', value: year.toString() })));
const includeYearSelectedFilters = ref<IncludeYearFilter>((useRoute().filters().get('includeYear') ?? []) as IncludeYearFilter);
type ExcludeYearFilter = FilterQueryParameter<'excludeYear'>[];
const excludeYearsItems = computed<ExcludeYearFilter>(() => getYearRange().map((year) => ({ key: 'excludeYear', value: year.toString() })));
const excludeYearSelectedFilters = ref<ExcludeYearFilter>((useRoute().filters().get('excludeYear') ?? []) as ExcludeYearFilter);

type IncludeInventoryTypeFilter = FilterQueryParameter<'includeInventoryType'>[];
const includeInventoryTypeSelectedFilters = ref<IncludeInventoryTypeFilter>((useRoute().filters().get('includeInventoryType') ?? []) as IncludeInventoryTypeFilter);
const includeInventoryTypeNewFilters = ref(includeInventoryTypeSelectedFilters.value.map((filter: FilterQueryParameter) => filter.value));
const includeInventoryTypesPreparedSelectedItems = computed<IncludeInventoryTypeFilter>(() => includeInventoryTypeNewFilters.value.map((id) => ({ key: 'includeInventoryType', value: id })));

type ExcludeInventoryTypeFilter = FilterQueryParameter<'excludeInventoryType'>[];
const excludeInventoryTypeSelectedFilters = ref<ExcludeInventoryTypeFilter>((useRoute().filters().get('excludeInventoryType') ?? []) as ExcludeInventoryTypeFilter);
const excludeInventoryTypeNewFilters = ref(excludeInventoryTypeSelectedFilters.value.map((filter: FilterQueryParameter) => filter.value));
const excludeInventoryTypesPreparedSelectedItems = computed<ExcludeInventoryTypeFilter>(() => excludeInventoryTypeNewFilters.value.map((id) => ({ key: 'excludeInventoryType', value: id })));

function filter() {
    return [includeYearSelectedFilters.value, excludeYearSelectedFilters.value, includeInventoryTypesPreparedSelectedItems.value, excludeInventoryTypesPreparedSelectedItems.value];
}
useInjectFilter(filter);

const { data: inventoryTypes, processing: fetchingInventoryTypes } = useFetchListData<IInventoryType>(useRoute().build(InventoryType.routes().index), 'appSection@inventoryType::IndexInventoryTypesPage');
</script>
