import { type AxiosResponse } from 'axios';

export interface CsvStructure {
    title: string;
    key: string;
}
export async function useDownloadCsv(filename: string, structure: CsvStructure[]): Promise<void> {
    const response: AxiosResponse = await window.axios.get(window.location.href, {
        responseType: 'blob',
        params: {
            csv: 1,
            csv_headers: structure.map((header) => header.title).join(','),
            csv_keys: structure.map((header) => header.key).join(','),
        },
    });
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}
