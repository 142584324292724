import { Model } from '@shared/models/Model';

import type { IModel } from '@shared/types/Model';

export interface IUserModel extends IModel {
    name: string;
    email: string;
    email_verified_at: string | null;
}

export abstract class UserModel extends Model implements IUserModel {
    public name!: string;
    public email!: string;
    public email_verified_at!: string | null;

    protected constructor(o: IUserModel) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof IUserModel)[] {
        return ['email_verified_at'];
    }
}
