<template>
    <v-row align-content="center" no-gutters justify="center">
        <v-col cols="10" sm="7" md="5" lg="3" xl="2" xxl="2">
            <form @submit.prevent="login.execute">
                <h1 class="welcome">Welcome to</h1>
                <h1 class="brand-name">GESG Carbon Registry</h1>
                <!--                <h1 class="mb-10 mx-auto text-center">Welcome to GESG Carbon Registry</h1>-->
                <v-card class="pa-5 ma-auto">
                    <v-text-field v-model="form.email" class="mb-2" :error-messages="transformError(form.errors.email)" autocomplete="username" label="Email" />
                    <PasswordField v-model:value="form.password" label="Password" :errors="transformError(form.errors.password)" />
                    <!--                    <v-checkbox v-model="form.remember">-->
                    <!--                        <template #label>-->
                    <!--                            <div>Stay Signed In</div>-->
                    <!--                        </template>-->
                    <!--                    </v-checkbox>-->
                    <v-btn :loading="form.processing" class="mt-5" color="primary" type="submit" width="100%"> Login </v-btn>
                    <!--                            <div class="mt-5 text-center">-->
                    <!--                                <a class="text-body-2" @click="forgotPassword">-->
                    <!--                                    Forgot Password-->
                    <!--                                </a>-->
                    <!--                            </div>-->
                </v-card>
            </form>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { useUtility } from '@shared/composables/useUtility';
import { useAuthenticationRepository } from '@app/authentication/repositories/useAuthenticationRepository';

defineOptions({ layout: null });

const { transformError } = useUtility();

const initialValues = {
    email: null,
    password: null,
    remember: false,
};
const login = useAuthenticationRepository().command.login(initialValues);
const form = login.form;
</script>

<style lang="scss" scoped>
.welcome,
.brand-name {
    text-align: start;
    font-size: 2rem;
}
.welcome {
    font-weight: 700;
    color: gray;
}
.brand-name {
    font-weight: 700;
    color: rgba(47, 49, 47, 0.96);
    margin-bottom: 2rem;
}
</style>
