<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Owners', to: useRoute().build(Owner.routes().index) }, { title: 'Owner Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.name" label="Name" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <GenericActionDialog confirm-btn-text="Update" title="Edit Owner" :disabled="false" @confirm="confirm($event)">
                        <template #activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" :loading="processing">Edit</v-btn>
                        </template>
                        <template #body>
                            <UpdateOwnerDialog :item="page.props.data" />
                        </template>
                    </GenericActionDialog>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { type IOwner, Owner } from '../../models/Owner';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm';
import { useRoute } from '@shared/composables/useRoute';
import type { SingleObjectResponse } from '@shared/types/ApiResponse';

const page = usePage<SingleObjectResponse<IOwner>>();

const { confirm, processing } = useSubmitForm();
</script>
