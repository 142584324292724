<template>
    <DataTable
        title="Properties"
        :headers="Property.routes().index.datatableHeaders()"
        :model-class="Property"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Add Property',
            updateDialogBtnText: 'Update Property',
            deleteDialogBtnText: 'Delete Property',
        }"
        :config="useConfig().property"
    >
        <template #top-bar>
            <v-row justify="end">
                <v-col cols="auto">
                    <v-btn @click="useDownloadCsv('properties', Property.csvHeaders())">Download CSV</v-btn>
                </v-col>
            </v-row>
        </template>
        <template #create-action-dialog="{ item }">
            <CreatePropertyDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdatePropertyDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeletePropertyDialog :item />
        </template>
        <template #custom-action="{ item }">
            <ShowButton :to="usePropertyRepository().query.show(item.id).endpoint" />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Property } from '@app/land-hold/property/models/Property';
import { useConfig } from '@shared/composables/useConfig';
import { useDownloadCsv } from '@shared/composables/useDownloadCsv';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';

const page = Property.routes().index.page;
</script>
