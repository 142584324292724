import { clientConfig } from '@app/purchase/client/configs/clientConfig';
import { blockConfig } from '@app/purchase/block/configs/blockConfig';
import { countryConfig } from '@app/location/country/configs/countryConfig';
import { inventoryConfig } from '@app/land-hold/inventory/configs/inventoryConfig';
import { inventoryQueueViewConfig } from '@app/land-hold/inventory/configs/inventoryQueueViewConfig';
import { inventoryViewConfig } from '@app/land-hold/inventory/configs/inventoryViewConfig';
import { propertyConfig } from '@app/land-hold/property/configs/propertyConfig';
import { landUseFactorConfig } from '@app/land-hold/land-use-factor/configs/landUseFactorConfig';
import { landUseTypeConfig } from '@app/land-hold/land-use-type/configs/landUseTypeConfig';
import { ownerConfig } from '@app/land-hold/owner/configs/ownerConfig';
import { partitionConfig } from '@app/land-hold/partition/configs/partitionConfig';
import { stateConfig } from '@app/location/state/configs/stateConfig';
import { stewardConfig } from '@app/land-hold/steward/configs/stewardConfig';
import { transactionConfig } from '@app/purchase/transaction/configs/transactionConfig';
import { publicTransactionConfig } from '@app/purchase/transaction/configs/publicTransactionConfig';
import { userConfig } from '@app/user/configs/userConfig';
import { inventoryTypeConfig } from '@app/land-hold/inventory-type/configs/inventoryTypeConfig';
import { transactionTypeConfig } from '@app/purchase/transaction-type/configs/transactionTypeConfig';
import type { Configs } from '@shared/types/Config';

export function useConfig(): Configs {
    return {
        client: clientConfig,
        block: blockConfig,
        country: countryConfig,
        inventory: inventoryConfig,
        inventoryType: inventoryTypeConfig,
        inventoryQueueView: inventoryQueueViewConfig,
        inventoryView: inventoryViewConfig,
        landUseFactor: landUseFactorConfig,
        landUseType: landUseTypeConfig,
        owner: ownerConfig,
        partition: partitionConfig,
        property: propertyConfig,
        state: stateConfig,
        steward: stewardConfig,
        transaction: transactionConfig,
        publicTransaction: publicTransactionConfig,
        transactionType: transactionTypeConfig,
        user: userConfig,
    };
}
