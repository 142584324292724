import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type ITransactionType, TransactionType } from '@app/purchase/transaction-type/models/TransactionType';
import type { IInventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';

export enum AvailableIncludes {
    InventoryType = 'inventory_type',
}

export interface InventoryTypeInclude {
    inventory_type: SingleObjectInclude<IInventoryType>;
}
export type AllTransactionTypeIncludes = ITransactionType & InventoryTypeInclude;
export type IndexTransactionTypeIncludes = AllTransactionTypeIncludes;
export function routes(): {
    index: IndexRoute<'transaction-types.index', IndexTransactionTypeIncludes>;
    show: ShowRoute<'transaction-types.show'>;
    destroy: DestroyRoute<'transaction-types.destroy'>;
    create: CreateRoute<'transaction-types.create'>;
    store: StoreRoute<'transaction-types.store'>;
    edit: EditRoute<'transaction-types.edit'>;
    update: UpdateRoute<'transaction-types.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'transaction-types.index',
            params: {
                include: [AvailableIncludes.InventoryType],
                orderBy: 'name',
                sortedBy: 'desc',
            },
            datatableHeaders: () => TransactionType.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'transaction-types.show', page: usePage() },
        destroy: { method: 'delete', name: 'transaction-types.destroy', page: usePage() },
        create: { method: 'get', name: 'transaction-types.create', page: usePage() },
        store: { method: 'post', name: 'transaction-types.store', page: usePage() },
        edit: { method: 'get', name: 'transaction-types.edit', page: usePage() },
        update: { method: 'patch', name: 'transaction-types.update', page: usePage() },
    };
}
