<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="[{ title: 'Users', to: useRoute().build(User.routes().index) }, { title: 'User Details' }]" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.name" label="Name" />
                            </v-col>
                            <v-col cols="12" sm="4" xl="2">
                                <ReadOnlyTextField :value="page.props.data.email" label="Email" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <GenericActionDialog confirm-btn-text="Update" title="Edit User" :disabled="false" @confirm="confirm($event)">
                        <template #activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" :loading="processing">Edit</v-btn>
                        </template>
                        <template #body>
                            <UpdateUserDialog :item="page.props.data" />
                        </template>
                    </GenericActionDialog>
                </v-card-actions>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { User } from '@app/user/models/User';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm';
import { useRoute } from '@shared/composables/useRoute';

const page = User.routes().show.page;

const { confirm, processing } = useSubmitForm();
</script>
