import type { App } from 'vue';
import vuetify from './vuetify';
// https://github.com/tighten/ziggy/discussions/565
import ziggy from './ziggy';
import link from '@core/plugins/link';
import './ChartJs';
import './axiox';
import Sentry from './Sentry';

export function registerPlugins(app: App) {
    app.use(vuetify);
    app.use(ziggy);
    app.use(link);
    if (import.meta.env.DEV) {
        // conditionally completely disable Sentry in development to prevent all overhead from Sentry instrumentation
        import.meta.env.VITE_SENTRY_ENABLED && app.use(Sentry);
    } else {
        // enable Sentry in production
        import.meta.env.PROD && app.use(Sentry);
    }
}
