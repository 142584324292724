import { router, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import type { App } from 'vue';

// https://github.com/vuetifyjs/vuetify/issues/11573#issuecomment-1465046711
// In case you forgot how to use Inertia "link" and you are back to read about it again!
// You can use it just like this on your Vue/Vuetify component:
// :to="route('route.name')"
export default {
    install(app: App) {
        app.component('RouterLink', {
            useLink(props: { to: Ref<string> }) {
                const href = props.to.value;
                const currentUrl = computed(() => usePage().url);
                return {
                    route: computed(() => ({ href })),
                    isActive: computed(() => currentUrl.value.startsWith(href)),
                    isExactActive: computed(() => href === currentUrl.value),
                    navigate(e: KeyboardEvent) {
                        if (e.shiftKey || e.metaKey || e.ctrlKey) return;
                        e.preventDefault();
                        router.visit(href);
                    },
                };
            },
        });
    },
};
