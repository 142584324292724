import { type IPartner, Partner } from '@app/purchase/partner/models/Partner';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';

export function routes(): {
    index: IndexRoute<'partners.index', IPartner>;
    show: ShowRoute<'partners.show'>;
    destroy: DestroyRoute<'partners.destroy'>;
    create: CreateRoute<'partners.create'>;
    store: StoreRoute<'partners.store'>;
    edit: EditRoute<'partners.edit'>;
    update: UpdateRoute<'partners.update'>;
} {
    return {
        index: { method: 'get', name: 'partners.index', datatableHeaders: () => Partner.headers(), page: usePage() },
        show: { method: 'get', name: 'partners.show', page: usePage() },
        destroy: { method: 'delete', name: 'partners.destroy', page: usePage() },
        create: { method: 'get', name: 'partners.create', page: usePage() },
        store: { method: 'post', name: 'partners.store', page: usePage() },
        edit: { method: 'get', name: 'partners.edit', page: usePage() },
        update: { method: 'patch', name: 'partners.update', page: usePage() },
    };
}
