<template>
    <p>Are you sure you want to delete this transaction?</p>
    <br />
    <p>[ {{ item.transaction_code }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type ITransaction } from '@app/purchase/transaction/models/Transaction';
import { useTransactionRepository } from '@app/purchase/transaction/repositories/useTransactionRepository';

const props = defineProps({
    item: {
        type: Object as PropType<ITransaction>,
        required: true,
    },
});

const destroy = useTransactionRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
