import type { IUserModel } from '@shared/models/User';
import { UserModel } from '@shared/models/User';
import { type CreateRoute, type DestroyRoute, type EditRoute, type IndexRoute, type ShowRoute, type StoreRoute, type UpdateRoute } from '@shared/types/Route';
import { useRoute } from '@shared/composables/useRoute';
import type { DataTableHeaders } from '@shared/types/Vuetify';

export interface IUser extends IUserModel {
    gender: string;
    birth: string;
}

export class User extends UserModel implements IUser {
    public gender!: string;
    public birth!: string;

    public constructor(o: IUser) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IUser> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 80, minWidth: '80', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name' },
            { title: 'Email', key: 'email', link: (item: IUser) => useRoute().build(User.routes().show, { user_id: item.id }) },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'users.index', IUser>;
        show: ShowRoute<'users.show', IUser>;
        destroy: DestroyRoute<'users.destroy', IUser>;
        create: CreateRoute<'users.create', IUser>;
        store: StoreRoute<'users.store', IUser>;
        edit: EditRoute<'users.edit', IUser>;
        update: UpdateRoute<'users.update', IUser>;
        profile: ShowRoute<'users.profile', IUser>;
    } {
        return {
            index: {
                method: 'get',
                name: 'users.index',
                params: {
                    orderBy: 'name',
                    sortedBy: 'desc',
                },
                datatableHeaders: () => User.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'users.show', page: usePage() },
            destroy: { method: 'delete', name: 'users.destroy', page: usePage() },
            create: { method: 'get', name: 'users.create', page: usePage() },
            store: { method: 'post', name: 'users.store', page: usePage() },
            edit: { method: 'get', name: 'users.edit', page: usePage() },
            update: { method: 'patch', name: 'users.update', page: usePage() },
            profile: { method: 'get', name: 'users.profile', page: usePage() },
        };
    }
}
