<template>
    <v-row align-content="center" no-gutters justify="center">
        <v-col cols="10" sm="7" md="5" lg="3" xl="2" xxl="2">
            <form @submit.prevent="login.execute">
                <div class="container">
                    <h1 class="welcome">Welcome to</h1>
                    <span class="api-text text-end">(Public API)</span>
                </div>
                <div class="container">
                    <h1 class="brand-name">GESG Carbon Registry</h1>
                </div>
                <v-card class="pa-5 ma-auto">
                    <v-text-field v-model="form.partner_id" class="mb-2" :error-messages="transformError(form.errors.partner_id)" autocomplete="username" label="Partner ID" />
                    <PasswordField v-model:value="form.access_token" label="Access Token" :errors="transformError(form.errors.access_token)" />
                    <v-btn :loading="form.processing" class="mt-5" color="primary" type="submit" width="100%"> Login </v-btn>
                </v-card>
            </form>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { useUtility } from '@shared/composables/useUtility';
import { useAuthenticationRepository } from '@app/authentication/repositories/useAuthenticationRepository';

defineOptions({ layout: null });

const { transformError } = useUtility();

const initialValues = {
    partner_id: null,
    access_token: null,
};
const login = useAuthenticationRepository().command.partnerLogin(initialValues);
const form = login.form;
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.welcome,
.brand-name {
    flex-grow: 1;
    text-align: start;
    font-size: 2.123rem;
}
.welcome {
    display: inline;
    font-weight: 700;
    color: gray;
}
.api-text {
    font-weight: 700;
    color: gray;
    font-size: 0.7rem;
}
.brand-name {
    font-weight: 700;
    color: rgba(47, 49, 47, 0.96);
    margin-bottom: 2rem;
}
</style>
