export function useUtility() {
    return {
        isNil,
        isNull,
        isUndefined,
        isEmptyString,
        isString,
        isArray,
        isEmptyArray,
        isFunction,
        isObject,
        transformError,
        allowedYearRange,
        currentYear,
        getYearRange,
    };
}

function isNil(value: unknown): value is null | undefined {
    return value === null || value === undefined;
}

function isNull(value: unknown): value is null {
    return value === null;
}

function isUndefined(value: unknown): value is undefined {
    return value === undefined;
}

function isEmptyString(value: unknown): value is '' {
    return value === '';
}

function isString(value: unknown): value is string {
    return typeof value === 'string';
}

function isArray(value: unknown): value is unknown[] {
    return Array.isArray(value);
}

function isEmptyArray(value: unknown): value is [] {
    return Array.isArray(value) && value.length === 0;
}

function isFunction(value: unknown): value is (...args: unknown[]) => unknown {
    return typeof value === 'function';
}

function isObject(value: unknown): value is Record<string, unknown> {
    return typeof value === 'object' && value !== null;
}

function transformError(error: string | undefined): string {
    if (error) {
        return error;
    }
    return '';
}

function allowedYearRange() {
    return [2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050];
}

function currentYear() {
    return new Date().getFullYear();
}

function getYearRange(from = 2020, to = 2030) {
    return allowedYearRange().filter((yearItem) => yearItem >= from && yearItem <= to);
}
