import type { SubmitableFn, SubmitableComponentRef } from '@shared/types/GenericActionDialog';

export function useProvideSubmitForm(): SubmitableComponentRef {
    const submitableComponentRef: SubmitableComponentRef = ref<SubmitableFn>(() => {
        throw new Error('submit method not implemented');
    });
    provide<SubmitableComponentRef>('submitableComponentRef', submitableComponentRef);

    return submitableComponentRef;
}
