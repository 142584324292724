import { Model } from '@shared/models/Model';
import { routes } from '@app/purchase/partner/routes/routes';

import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IPartner extends IModel {
    name: string;
    contact_info: string;
    steward_id: string;
}

export class Partner extends Model implements IPartner {
    public name!: string;
    public contact_info!: string;
    public steward_id!: string;

    public constructor(o: IPartner) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IPartner> {
        return [
            {
                title: 'Actions',
                key: 'actions',
                sortable: false,
                fixed: true,
                width: 113,
                minWidth: '113',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
                cellProps() {
                    return { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } };
                },
            },
            { title: 'Name', key: 'name' },
            { title: 'Contact Info', key: 'contact_info', sortable: false },
            // { title: 'Steward', key: 'steward.data.name', sortable: false },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
