<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { type IUser } from '@app/user/models/User';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useUserRepository } from '@app/user/repositories/useUserRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IUser>,
        required: true,
    },
});

const update = useUserRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);
</script>
