import { Model } from '@shared/models/Model';
import { type IState } from '@app/location/state/models/State';
import { useClientRepository } from '@app/purchase/client/repositories/useClientRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IModel } from '@shared/types/Model';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import type { ICountry } from '@app/location/country/models/Country';

export interface IClient extends IModel {
    airtable_id: string;
    company_code: string;
    name: string;
    state_id: string | null;
    country_id: string | null;
    airtable_state_id: string;
    state: SingleObjectInclude<IState> | null;
    country: SingleObjectInclude<ICountry> | null;
}

export class Client extends Model implements IClient {
    airtable_id!: string;
    company_code!: string;
    name!: string;
    state_id!: string;
    country_id!: string;
    airtable_state_id!: string;
    state!: SingleObjectInclude<IState>;
    country!: SingleObjectInclude<ICountry>;

    public constructor(o: IClient) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IClient> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Company Code', key: 'company_code', link: (item: IClient) => useClientRepository().query.show(item.id).endpoint },
            { title: 'Name', key: 'name' },
            { title: 'Country', key: 'country.data.name' },
            { title: 'State', key: 'state.data._3_letter_code' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'clients.index', IClient>;
        show: ShowRoute<'clients.show', IClient>;
        destroy: DestroyRoute<'clients.destroy'>;
        create: CreateRoute<'clients.create'>;
        store: StoreRoute<'clients.store'>;
        edit: EditRoute<'clients.edit'>;
        update: UpdateRoute<'clients.update'>;
    } {
        return {
            index: { method: 'get', name: 'clients.index', params: { orderBy: 'name', sortedBy: 'desc' }, datatableHeaders: () => Client.headers(), page: usePage() },
            show: { method: 'get', name: 'clients.show', page: usePage() },
            destroy: { method: 'delete', name: 'clients.destroy', page: usePage() },
            create: { method: 'get', name: 'clients.create', page: usePage() },
            store: { method: 'post', name: 'clients.store', page: usePage() },
            edit: { method: 'get', name: 'clients.edit', page: usePage() },
            update: { method: 'patch', name: 'clients.update', page: usePage() },
        };
    }
}
