<template>
    <p>Are you sure you want to delete this Owner?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { type IOwner } from '../../models/Owner';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IOwner>,
        required: true,
    },
});

const destroy = useOwnerRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
