import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';

export type AllInventoryTypeIncludes = IInventoryType;
export type IndexInventoryTypeIncludes = AllInventoryTypeIncludes;
export function routes(): {
    index: IndexRoute<'inventory-types.index', IndexInventoryTypeIncludes>;
    show: ShowRoute<'inventory-types.show'>;
    destroy: DestroyRoute<'inventory-types.destroy'>;
    create: CreateRoute<'inventory-types.create'>;
    store: StoreRoute<'inventory-types.store'>;
    edit: EditRoute<'inventory-types.edit'>;
    update: UpdateRoute<'inventory-types.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'inventory-types.index',
            params: {
                orderBy: 'name',
                sortedBy: 'desc',
            },
            datatableHeaders: () => InventoryType.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'inventory-types.show', page: usePage() },
        destroy: { method: 'delete', name: 'inventory-types.destroy', page: usePage() },
        create: { method: 'get', name: 'inventory-types.create', page: usePage() },
        store: { method: 'post', name: 'inventory-types.store', page: usePage() },
        edit: { method: 'get', name: 'inventory-types.edit', page: usePage() },
        update: { method: 'patch', name: 'inventory-types.update', page: usePage() },
    };
}
