import { Model } from '@shared/models/Model';
import { type IndexOwnerIncludes, routes } from '@app/land-hold/owner/routes/routes';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';

export interface IOwner extends IModel {
    airtable_id: string;
    name: string;
}

export class Owner extends Model implements IOwner {
    airtable_id!: string;
    name!: string;

    public constructor(o: IOwner) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<IndexOwnerIncludes> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Name', key: 'name', link: (item: IndexOwnerIncludes) => useOwnerRepository().query.show(item.id).endpoint },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
