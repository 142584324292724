<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { type IOwner } from '../../models/Owner';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useOwnerRepository } from '@app/land-hold/owner/repositories/useOwnerRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IOwner>,
        required: true,
    },
});

const store = useOwnerRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
</script>
