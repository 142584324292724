<template>
    <DataTable
        title="Users"
        :headers="User.headers()"
        :items="page.props.data"
        :model-class="User"
        :crud-config="{
            updateDialogBtnText: 'Update User',
            showCreateAction: false,
            showDeleteAction: false,
        }"
        :config="useConfig().user"
    >
        <template #update-action-dialog="{ item }">
            <UpdateUserDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { type IUser, User } from '../../models/User';
import { useConfig } from '@shared/composables/useConfig';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<IUser>>();
</script>
