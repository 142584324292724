<template>
    <v-dialog v-model="showDialog" max-width="500px" persistent location-strategy="connected" :target="location">
        <template #activator="{ props: activatorProps }">
            <slot :props="activatorProps" name="activator" />
        </template>

        <template #default>
            <v-card>
                <v-card-title class="text-start text-wrap">
                    {{ title }}
                </v-card-title>

                <v-card-text>
                    <slot name="body" />
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn variant="text" @click="cancel()"> {{ cancelBtnText }}</v-btn>
                    <v-btn :color="confirmBtnColor" :disabled="disabled" :loading="loading" variant="text" type="submit" @click="confirm()">
                        {{ confirmBtnText }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup lang="ts">
defineProps({
    title: {
        type: String,
        default: '',
    },
    confirmBtnText: {
        type: String,
        default: 'Confirm',
    },
    cancelBtnText: {
        type: String,
        default: 'Cancel',
    },
    confirmBtnColor: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: false,
    },
});

const display = useDisplay();
const location = computed(() => [display.width.value / 2, display.height.value / 2] as [number, number]);

const emit = defineEmits(['cancel', 'confirm', 'open']);

const showDialog = ref(false);

watch(showDialog, (val) => {
    !val || emit('open');
});

const close = () => {
    showDialog.value = false;
};

const cancel = () => {
    close();
    emit('cancel');
};

const confirm = () => {
    emit('confirm', close);
};
</script>
