<template>
    <AdminPanelTemplate>
        <template v-if="showBreadcrumbs" #breadcrumbs>
            <Breadcrumbs :items="breadcrumbs" />
        </template>
        <template #content>
            <v-container fluid>
                <DataTableActionBar v-if="normalizedConfig.showCreateAction || $slots['top-bar']" class="mb-1">
                    <template #first-line>
                        <v-row justify="space-between">
                            <v-col>
                                <GenericActionDialog v-if="normalizedConfig.showCreateAction" :disabled="hasError" :confirm-btn-text="normalizedConfig.createDialogBtnText" :title="normalizedConfig.createDialogTitle" :loading="processing" @confirm="confirm($event)">
                                    <template #activator="{ props: activatorProps }">
                                        <v-btn v-bind="activatorProps" prepend-icon="mdi-plus" color="primary">
                                            {{ normalizedConfig.createDialogBtnText }}
                                        </v-btn>
                                    </template>
                                    <template #body>
                                        <slot :item="staticModel.empty() as T" name="create-action-dialog" :items="selectedItems" />
                                    </template>
                                </GenericActionDialog>
                            </v-col>
                            <v-col>
                                <slot name="top-bar" :items="items" />
                            </v-col>
                        </v-row>
                    </template>
                </DataTableActionBar>

                <v-card>
                    <v-card-title v-if="showToolbar" class="px-2">
                        <DataTableHeader v-model:search="search" :show-search="showSearch!!" :title="title" :loading="processing">
                            <template #actions>
                                <GenericActionDialog v-if="$slots['filter-dialog']" confirm-btn-text="Apply" title="Filter" :disabled="hasError" @confirm="applyFilter">
                                    <template #activator="{ props: activatorProps }">
                                        <v-badge v-if="filters?.length" color="primary" :content="filters.length" location="start center">
                                            <v-btn v-bind="activatorProps" variant="text" icon="mdi-filter-variant" :loading="processing" />
                                        </v-badge>
                                        <v-btn v-else v-bind="activatorProps" variant="text" icon="mdi-filter-variant" :loading="processing" />
                                    </template>
                                    <template #body>
                                        <slot name="filter-dialog" />
                                    </template>
                                </GenericActionDialog>
                            </template>
                        </DataTableHeader>
                    </v-card-title>

                    <v-divider v-if="showToolbar" />

                    <v-data-table-server v-model:expanded="expanded" v-model="selectedItems" :item-selectable="itemSelectable" :must-sort="mustSort" multi-sort :theme="theme" fixed-header :show-select="showSelect" :show-expand="showExpand" return-object :search="datatableTrigger" :disable-sort="processing" :items="items" item-value="id" :headers="headers" :items-length="page.props.meta.pagination.total" :loading="processing" :page="page.props.meta.pagination.current_page" :items-per-page="page.props.meta.pagination.per_page" @update:options="applyOptionChanges">
                        <template #[`header.data-table-expand`]>
                            <v-btn size="small" variant="text" :icon="expandAll ? 'mdi-chevron-up' : 'mdi-chevron-down'" @click="expandAll = !expandAll" />
                        </template>

                        <template #expanded-row="{ item, columns }">
                            <tr>
                                <td :colspan="columns.length">
                                    <slot name="expanded-row" :item="item" />
                                </td>
                            </tr>
                        </template>

                        <template v-for="(field, i) in staticModel.chips()" :key="i" #[`item.${field}`]="{ item }">
                            <v-chip rounded="pill" :size="staticModel.chipConfig(field, item).size ?? 'small'" :color="staticModel.chipConfig(field, item).color ?? ''">{{ staticModel.chipConfig(field, item).text ?? item[field] }}</v-chip>
                        </template>

                        <template #[`item.actions`]="{ item }">
                            <GenericActionDialog v-if="normalizedConfig.showUpdateAction" :disabled="hasError" :confirm-btn-text="normalizedConfig.updateDialogBtnText" :title="normalizedConfig.updateDialogTitle" :loading="processing" @confirm="confirm($event)">
                                <template #activator="{ props: activatorProps }">
                                    <v-btn size="small" v-bind="activatorProps" variant="text" icon="mdi-pencil" :disabled="!isEditable(item)" />
                                </template>
                                <template #body>
                                    <slot :item="item" name="update-action-dialog" :has-error="hasError" />
                                </template>
                            </GenericActionDialog>

                            <GenericActionDialog v-if="normalizedConfig.showDeleteAction" :disabled="hasError" confirm-btn-color="error" :confirm-btn-text="normalizedConfig.deleteDialogBtnText" :title="normalizedConfig.deleteDialogTitle" :loading="processing" @confirm="confirm($event)">
                                <template #activator="{ props: activatorProps }">
                                    <v-btn size="small" v-bind="activatorProps" variant="text" icon="mdi-delete" :disabled="!isDeletable(item)" />
                                </template>
                                <template #body>
                                    <slot :item="item" name="delete-action-dialog" />
                                </template>
                            </GenericActionDialog>

                            <GenericActionDialog v-if="normalizedConfig.showCustomAction" :disabled="hasError" :confirm-btn-text="normalizedConfig.customDialogBtnText" :title="normalizedConfig.customDialogTitle" :loading="processing" @confirm="confirm($event)">
                                <template #activator="{ props: activatorProps }">
                                    <v-btn size="small" v-bind="activatorProps" variant="text" :icon="normalizedConfig.customDialogBtnIcon" :disabled="isNil(normalizedConfig.customDialogBtnDisabled) ? false : !normalizedConfig.customDialogBtnDisabled(item)" />
                                </template>
                                <template #body>
                                    <slot :item="item" name="custom-action-dialog" />
                                </template>
                            </GenericActionDialog>

                            <slot :item="item" name="custom-action" />
                        </template>

                        <template #[`item.created_at`]="{ item }">
                            {{ dateUtil.toISO8601(item.created_at) }}
                        </template>
                        <template #[`item.updated_at`]="{ item }">
                            {{ dateUtil.toISO8601(item.updated_at) }}
                        </template>

                        <template v-for="column in dateColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                            {{ dateUtil.toISO8601(getValue(item, column.key)) }}
                        </template>

                        <template v-for="column in linkColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                            <InertiaLink v-if="column.link" :href="column.link(item)">{{ getValue(item, column.key) }}</InertiaLink>
                        </template>

                        <template v-for="column in htmlColumns" :key="column.key" #[`item.${column.key}`]="{ item }">
                            <span v-html="getColumnValue(item, column)" />
                        </template>

                        <template v-if="!showPagination" #bottom />
                    </v-data-table-server>
                </v-card>
            </v-container>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts" generic="T extends IModel, U extends Model">
// https://vuejs.org/api/sfc-script-setup.html#generics
// https://stackoverflow.com/questions/70542599/how-to-type-a-generic-component-in-vue-3s-script-setup
import { Model } from '@shared/models/Model';
import type { Ref } from 'vue';
import { useInteractWithDataTable } from '@shared/composables/data-table/useInteractWithDataTable';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm';
import { useCrudConfig } from '@shared/composables/data-table/useCrudConfig';
import { useDebounceFn } from '@vueuse/core';
import { useUtility } from '@shared/composables/useUtility';
import type { FilterQueryParameterPayload, SearchQueryParameter } from '@core/types/shared-props';
import { useRoute } from '@shared/composables/useRoute';
import type { BreadcrumbItem } from '@shared/types/BreadcrumbItem';
import { useDate } from '@shared/composables/useDate';
import { useProvideErrorState } from '@shared/composables/useProvideErrorState';
import type { DataTableHeader, DataTableHeaders, DataTableQuery } from '@shared/types/Vuetify';
import type { PaginatedResponse } from '@shared/types/ApiResponse';
import type { GeneralConfig } from '@shared/types/Config';
import type { CrudConfig } from '@shared/types/DataTable';
import { useProvideFilter } from '@shared/composables/action-dialog/useProvideFilter';
import type { IModel, IModelStatic } from '@shared/types/Model';

// https://stackoverflow.com/questions/68602712/extracting-the-prop-types-of-a-component-in-vue-3-typescript-to-use-them-somew
// type ExtractSetupProps<T> = T extends { setup?(props: infer P, ...rest: any[]): any } ? (P extends any ? P : never) : never;
// type ExtractConstructedProps<T> = T extends { new (...args: any[]): { $props: infer X } } ? (X extends Record<string, any> ? X : never) : never;
// export type PublicPropsOf<T> = Pick<ExtractConstructedProps<T>, keyof ExtractSetupProps<T>>;

interface DataTableProps<T, U> {
    title: string;
    items: T[];
    headers: DataTableHeaders<T>;
    modelClass: U;
    // TODO: I think we can use conditional slots to get rid of this prop
    // https://vuejs.org/guide/components/slots.html#conditional-slots
    crudConfig?: CrudConfig;
    breadcrumbItems?: BreadcrumbItem[];
    showBreadcrumbs?: boolean;
    showToolbar?: boolean;
    showSearch?: boolean;
    showSelect?: boolean;
    showExpand?: boolean;
    itemSelectable?: (item: T) => boolean;
    showPagination?: boolean;
    searchEndpoint?: () => string;
    theme?: string;
    config: GeneralConfig;
}

const props = withDefaults(defineProps<DataTableProps<T, U>>(), {
    title: '',
    breadcrumbItems: (): BreadcrumbItem[] => [] as BreadcrumbItem[],
    showBreadcrumbs: true,
    showToolbar: true,
    showSearch: true,
    showSelect: false,
    showExpand: false,
    showPagination: true,
});

const page = usePage<PaginatedResponse<T>>();
const staticModel = computed<IModelStatic<U>>(() => props.modelClass as unknown as IModelStatic<U>);
const { confirm, processing: formProcessing, wasSuccessful } = useSubmitForm();
const hasError = useProvideErrorState();
const { applyQuery, processing: tableProcessing } = useInteractWithDataTable();
const { isEmptyArray, isFunction, isNil } = useUtility();
const normalizedConfig = useCrudConfig().normalize(props.crudConfig);
const dateUtil = useDate();
const currentQuery = computed(() => page.props.shared.query);
const search: Ref<SearchQueryParameter> = ref<SearchQueryParameter>((currentQuery.value.search ?? '') as SearchQueryParameter);
const processing = computed<boolean>(() => tableProcessing.value || formProcessing.value);
const dateColumns = computed(() => props.headers.filter((column) => column.date));
const linkColumns = computed(() => props.headers.filter((column) => column.link));
const htmlColumns = computed(() => props.headers.filter((column) => column.renderAsHtml));
const mustSort = computed(() => (dataTableQuery.value ? dataTableQuery.value.sortBy.length < 2 : false));
const selectedItems: Ref<T[]> = ref([]);
const datatableTrigger = ref<string>('');
// TODO: this is a hack to make the table expand all button work
// The expanded ref should be a ref of T[] but it is a ref of string[] because of the v-data-table-server component
// Also look for expanded usage everywhere.
// We might have had to cast it to some stupid value to make it work
const expanded: Ref<string[]> = ref([]);
const expandAll = ref(false);
const dataTableQuery = ref<DataTableQuery>();
// TODO: move index routes to their respective repositories
// TODO: move routes() method and all route related details maybe to repository?
const getSearchEndpoint = computed(() => props.searchEndpoint ?? (() => useRoute().build(staticModel.value.routes().index)));
const filterComponentRef = useProvideFilter();
const filters: Ref<FilterQueryParameterPayload> = ref(useRoute().filters().current());

function applyOptionChanges(event: DataTableQuery) {
    dataTableQuery.value = applyQuery(event, currentQuery.value, search.value, getSearchEndpoint.value, filters.value, props.config.sortFieldMap);
}
function applyFilter(closeDialog: () => void) {
    closeDialog();
    filters.value = filterComponentRef.value();
    reloadDatatable();
}

function reloadDatatable() {
    datatableTrigger.value = Date.now().toString();
}

watch(
    search,
    useDebounceFn(() => {
        reloadDatatable();
    }, 400),
);

watch(wasSuccessful, (newValue) => {
    if (newValue) {
        selectedItems.value = [];
    }
});

watch(
    expandAll,
    (newValue) => {
        if (newValue) {
            if (isEmptyArray(expanded.value)) {
                expanded.value = props.items.map((item) => item) as unknown as string[];
            }
        } else {
            if (!isEmptyArray(expanded.value)) {
                expanded.value = [];
            }
        }
    },
    { immediate: true },
);

watch(expanded, (newValue) => {
    expandAll.value = !isEmptyArray(newValue);
});

const breadcrumbs = computed<BreadcrumbItem[]>((): BreadcrumbItem[] => {
    return isEmptyArray(props.breadcrumbItems) ? [{ title: props.title, disabled: true }] : props.breadcrumbItems;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getValue(item: any, key: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return key.split('.').reduce((o, k) => o[k], item);
}
function getColumnValue(item: T, column: DataTableHeader<T>): unknown {
    return isFunction(column.value) ? column.value(item, '') : column.value;
}
function isDeletable(item: T): boolean {
    return staticModel.value.create(item).can().delete;
}
function isEditable(item: T): boolean {
    return staticModel.value.create(item).can().update;
}
</script>

<style lang="scss">
table {
    thead {
        tr {
            td {
                font-family: 'Roboto Mono', sans-serif;
                white-space: nowrap;
            }
            th {
                div {
                    // I believe these two are the same
                    white-space: nowrap;
                    //min-width: max-content;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                font-family: 'Roboto Mono', sans-serif;
                white-space: nowrap;
            }
        }
    }
}
</style>
