<template>
    <DataTable
        title="Partitions"
        :headers="Property.routes().partitions.index.datatableHeaders()"
        :model-class="Partition"
        :items="page.props.data"
        :search-endpoint="() => usePropertyRepository().query.indexPropertyPartitions(page.props.property.data.id).endpoint"
        :crud-config="{
            createDialogBtnText: 'Create Partition',
            updateDialogBtnText: 'Update Partition',
            deleteDialogBtnText: 'Delete Partition',
            showUpdateAction: false,
        }"
        :breadcrumb-items="useBreadcrumb([[usePropertyRepository().query.show(property.data.id).endpoint, useRoute().build(Inventory.routes().management.properties.show, { id: property.data.id })]]).breadcrumbs(page.props.breadcrumbs)"
        :config="useConfig().partition"
    >
        <!--        :breadcrumb-items="[{ title: 'Properties', to: useRoute().build(Property.routes().index) }, { title: 'Property Details', to: useRoute().build(Property.routes().show, { id: property.data.id }) }, { title: 'Partitions' }]"-->
        <template #top-bar>
            <v-row>
                <v-col cols="12" sm="3">
                    <v-text-field v-model="property.data.property_area_ha" variant="solo" label="Total Area (ha)" readonly hide-details :loading="fetching" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field v-model="property.data.unpartition_area" variant="solo" label="Unpartitioned Area (ha)" readonly hide-details :loading="fetching" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field v-model="property.data.partitioned_area" variant="solo" label="Partitioned Area (ha)" readonly hide-details :loading="fetching" />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-text-field v-model="property.data.partition_status" variant="solo" label="Partition Status" readonly hide-details :loading="fetching" />
                </v-col>
            </v-row>
        </template>
        <template #create-action-dialog="{ item }">
            <CreatePartitionDialog :item :property="property" @unmounted="fetchProperty" />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeletePartitionDialog :item @deleted="fetchProperty" @unmounted="fetchProperty" />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Partition } from '@app/land-hold/partition/models/Partition';
import { type IProperty, Property } from '@app/land-hold/property/models/Property';
import { useRoute } from '@shared/composables/useRoute';
import { useConfig } from '@shared/composables/useConfig';
import { useBreadcrumb } from '@shared/composables/useBreadcrumb';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import type { SingleObjectResponse } from '@shared/types/ApiResponse';

const page = Property.routes().partitions.index.page;
const property = ref(page.props.property);

const fetching = ref(false);

function fetchProperty() {
    router.reload({
        only: ['property'],
        onStart() {
            fetching.value = true;
        },
        onSuccess(page) {
            property.value = page.props['property'] as SingleObjectResponse<IProperty>;
        },
        onFinish() {
            fetching.value = false;
        },
    });
}
</script>
