import { type App } from 'vue';
import { router } from '@inertiajs/vue3';
import * as Sentry from '@sentry/browser';

export default {
    install(app: App) {
        app.mixin({ methods: { route } });
        const client = InitSentryClient(app);
        createPageLoadSpan(client);
    },
};

// https://github.com/getsentry/sentry-javascript/issues/11362#issuecomment-2400733402
function InitSentryClient(app: App) {
    return Sentry.init({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        app,
        enabled: import.meta.env.VITE_SENTRY_ENABLED,
        environment: import.meta.env.VITE_APP_ENV,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            Sentry.browserTracingIntegration({
                // disable automatic span creation
                instrumentNavigation: false,
                instrumentPageLoad: false,
                enableInp: true,
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.replayCanvasIntegration(),
            Sentry.captureConsoleIntegration({
                // Add 'warn', 'error', 'debug', 'assert' console output types to Sentry
                // and additionally possible to add 'log' and 'info' console output types to Sentry if required
                levels: ['warn', 'error', 'debug', 'assert'],
            }),
            Sentry.contextLinesIntegration({
                frameContextLines: 50,
            }),
            Sentry.httpClientIntegration(),
        ],

        sendDefaultPii: true,
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
        replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
        autoSessionTracking: import.meta.env.VITE_SENTRY_AUTO_SESSION_TRACKING,
        telemetry: false,
        denyUrls: [
            // All browser extensions
            /extensions\//i,
            /^safari-extension:\/\//i,
            /^safari-web-extension:\/\//i,
            /^moz-extension:\/\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /moz-extension/i,
        ],
        ignoreErrors: [
            // Let's ignore some output as unnecessary for us now
            '@webkit-masked-url',
            'debugger eval',
            'Not implemented',
            'top.GLOBALS',
            // 'Failed to fetch',
        ],
    });
}

function currentRoute() {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return '/' + route().current();
}

// We should start the pageload span as early as possible!
function createPageLoadSpan(client: ReturnType<typeof InitSentryClient>): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Sentry.startBrowserTracingPageLoadSpan(client!, { op: 'pageload', name: currentRoute() });
}

// This function has to be called after the app.mount(el) call in the app.ts file
export function setupSentryBrowserTracing() {
    router.on('start', (event) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const client = Sentry.getClient()!;
        const newName = event.detail.visit.url.pathname;
        if (newName && newName !== currentRoute()) {
            // Navigation to other page e.g. /home -> /new-page
            Sentry.startBrowserTracingNavigationSpan(client, {
                op: 'navigation',
                name: newName,
                attributes: {
                    [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
                },
            });
        } else {
            // Navigation to the same page e.g. /home -> /home without page reload (INERTIA)
            Sentry.startBrowserTracingNavigationSpan(client, {
                op: 'navigation',
                name: newName,
                attributes: {
                    [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: 'route',
                },
            });
        }
    });

    router.on('finish', () => {
        const name = currentRoute();
        if (name) {
            const span = Sentry.getActiveSpan();
            const op = span && Sentry.spanToJSON(span).op;
            if (op === 'pageload' || op === 'navigation') {
                if (span) {
                    span.updateName(name);
                }
            }
        }
    });
}
