import { useForm } from '@shared/composables/useForm';
import { useRoute } from '@shared/composables/useRoute';
import { usePayload } from '@shared/composables/usePayload';
import { useInjectErrorState } from '@shared/composables/useProvideErrorState';
import { useWithValidationConfig } from '@shared/composables/useWithValidationConfig';
import type { IUser } from '@app/user/models/User';
import { User } from '@app/user/models/User';
import type { FormOptions } from '@shared/types/Form';

export function useUserRepository<T extends IUser = IUser>() {
    const command = () => {
        interface UpdatePassword {
            current_password: string | null;
            new_password: string | null;
            new_password_confirmation: string | null;
        }
        const updatePassword = (id: string) => {
            const form = useForm<UpdatePassword>({
                current_password: null,
                new_password: null,
                new_password_confirmation: null,
            });
            function execute(options?: FormOptions) {
                form.submit(
                    User.routes().update.method,
                    useRoute().build(User.routes().update, {
                        user_id: id,
                    }),
                    options,
                );
            }

            return { form, execute };
        };

        const update = (id: string, data: T, options?: FormOptions) => {
            const hasError = useInjectErrorState();
            const form = useForm(data).precognition(
                {
                    url: useRoute().build(User.routes().update, {
                        user_id: id,
                    }),
                    method: User.routes().update.method,
                },
                useWithValidationConfig(hasError, () => form, options),
            );
            function execute(options?: FormOptions) {
                form.transform((data) =>
                    usePayload<T>(data, {
                        exclude: User.create(data).hidden(),
                    }),
                ).submit(options);
            }

            return { form, execute };
        };

        return {
            update,
            updatePassword,
        };
    };

    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(User.routes().show, { user_id: id }) };
        };

        return {
            show,
        };
    };

    return {
        command: command(),
        query: query(),
    };
}
