import { useFetchInertiaPageJson } from '@shared/composables/useFetchInertiaPageJson.js';
import type { AxiosRequestConfig } from 'axios';
import type { ListResponse } from '@shared/types/ApiResponse';

export function useFetchListData<T>(url: string, component: string, partialData: string[] = [], config?: AxiosRequestConfig) {
    const processing = ref(true);
    const data: Ref<T[]> = ref([]);

    useFetchInertiaPageJson<ListResponse<T>>(url, component, partialData, {
        ...config,
        ...{
            params: {
                limit: 0,
            },
        },
    })
        .then(({ data: page }) => {
            data.value = (page.props as ListResponse<T>).data;
        })
        .catch((err: unknown) => {
            console.error(err);
        })
        .finally(() => {
            processing.value = false;
        });

    return {
        data,
        processing,
    };
}
