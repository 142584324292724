<template>
    <Bar :data="data" :options="options" css-classes="chart-container" />
</template>
<script setup lang="ts" generic="T">
import { Bar } from 'vue-chartjs';
import { type ChartOptions, Chart, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale, BarElement);

defineProps({
    data: {
        type: Object as PropType<T>,
        required: true,
    },
    options: {
        type: Object as PropType<ChartOptions<'bar'>>,
        default: () => ({}) as ChartOptions<'bar'>,
    },
});
</script>
