import { useRoute } from '@shared/composables/useRoute';
import { Block } from '@app/purchase/block/models/Block';

export function useBlockRepository() {
    const query = () => {
        const show = (id: string) => {
            return { endpoint: useRoute().build(Block.routes().show, { id }) };
        };

        return {
            show,
        };
    };

    return {
        query: query(),
    };
}
