import type { FilterComponentRef, FilterFn } from '@shared/types/GenericActionDialog';

export function useInjectFilter(filter: FilterFn) {
    const filterComponentRef = inject<FilterComponentRef>('filterComponentRef');
    onMounted(() => {
        if (filterComponentRef) {
            filterComponentRef.value = filter;
        }
    });
}
