import { Model } from '@shared/models/Model';
import type { IInventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { routes } from '@app/purchase/transaction-type/routes/routes';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IModel } from '@shared/types/Model';

export interface ITransactionType extends IModel {
    name: string;
    inventory_type_id: string;
    inventory_type: SingleObjectInclude<IInventoryType>;
}

export class TransactionType extends Model implements ITransactionType {
    public name!: string;
    public inventory_type_id!: string;
    public inventory_type!: SingleObjectInclude<IInventoryType>;

    public constructor(o: ITransactionType) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof ITransactionType)[] {
        return ['inventory_type'];
    }

    public static override headers(): DataTableHeaders<ITransactionType> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: 'Transaction Type Name', key: 'name' },
            { title: 'Inventory Type Name', key: 'inventory_type.data.name' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): ReturnType<typeof routes> {
        return routes();
    }
}
