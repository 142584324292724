import { type IProperty } from '@app/land-hold/property/models/Property';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IOwner, Owner } from '@app/land-hold/owner/models/Owner';
import type { MultiObjectInclude } from '@shared/types/ApiResponse';

export enum AvailableIncludes {
    Properties = 'properties',
}

export interface PropertiesInclude {
    properties: MultiObjectInclude<IProperty>;
}
export type AllOwnerIncludes = IOwner & PropertiesInclude;
export type IndexOwnerIncludes = AllOwnerIncludes;
export function routes(): {
    index: IndexRoute<'owners.index', IndexOwnerIncludes>;
    show: ShowRoute<'owners.show'>;
    destroy: DestroyRoute<'owners.destroy'>;
    create: CreateRoute<'owners.create'>;
    store: StoreRoute<'owners.store'>;
    edit: EditRoute<'owners.edit'>;
    update: UpdateRoute<'owners.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'owners.index',
            params: { include: [AvailableIncludes.Properties], orderBy: 'name', sortedBy: 'desc' },
            datatableHeaders: () => Owner.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'owners.show', page: usePage() },
        destroy: { method: 'delete', name: 'owners.destroy', page: usePage() },
        create: { method: 'get', name: 'owners.create', page: usePage() },
        store: { method: 'post', name: 'owners.store', page: usePage() },
        edit: { method: 'get', name: 'owners.edit', page: usePage() },
        update: { method: 'patch', name: 'owners.update', page: usePage() },
    };
}
