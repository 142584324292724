<template>
    <DataTable
        title="Owners"
        :headers="Owner.headers()"
        :model-class="Owner"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Owner',
            updateDialogBtnText: 'Update Owner',
            deleteDialogBtnText: 'Delete Owner',
        }"
        :config="useConfig().owner"
    >
        <template #create-action-dialog="{ item }">
            <CreateOwnerDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateOwnerDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteOwnerDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { Owner } from '@app/land-hold/owner/models/Owner';
import { useConfig } from '@shared/composables/useConfig';

const page = Owner.routes().index.page;
</script>
