<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Company Code" :field="'company_code'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.country_id" v-model:form="form" :field-name="'country_id'" :items="countries" :loading="fetchingCountries" label="Country" />
            </v-col>
            <v-col v-show="countryIsMexico" cols="12">
                <Autocomplete v-model:selected-items="form.state_id" v-model:form="form" :field-name="'state_id'" :items="states" :loading="fetchingStates" label="State" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { type IClient } from '@app/purchase/client/models/Client';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IState, State } from '@app/location/state/models/State';
import { useFetchInertiaPageJson } from '@shared/composables/useFetchInertiaPageJson';
import { useRoute } from '@shared/composables/useRoute';
import { useClientRepository } from '@app/purchase/client/repositories/useClientRepository';
import type { ListResponse } from '@shared/types/ApiResponse';
import { Country, type ICountry } from '@app/location/country/models/Country';

const props = defineProps({
    item: {
        type: Object as PropType<IClient>,
        required: true,
    },
});

const update = useClientRepository().command.update(props.item.id, props.item);
const form = update.form;
useInjectSubmitForm(update.execute);

const states: Ref<IState[]> = ref([]);
const fetchingStates = ref(false);
function fetchStates() {
    fetchingStates.value = true;
    useFetchInertiaPageJson<ListResponse<IState>>(useRoute().build(State.routes().index), 'appSection@state::IndexStatesPage', [], {
        params: {
            limit: 0,
        },
    })
        .then(({ data: page }) => {
            states.value = (page.props as ListResponse<IState>).data;
        })
        .catch((err: unknown) => {
            console.error(err);
        })
        .finally(() => {
            fetchingStates.value = false;
        });
}

const countries: Ref<ICountry[]> = ref([]);
const fetchingCountries = ref(false);
function fetchCountries() {
    fetchingCountries.value = true;
    useFetchInertiaPageJson<ListResponse<ICountry>>(useRoute().build(Country.routes().index), 'appSection@country::IndexCountriesPage', [], {
        params: {
            limit: 0,
        },
    })
        .then(({ data: page }) => {
            countries.value = (page.props as ListResponse<ICountry>).data;
        })
        .catch((err: unknown) => {
            console.error(err);
        })
        .finally(() => {
            fetchingCountries.value = false;
        });
}

const countryIsMexico = computed(() => form.country_id === countries.value.find((country) => country.country_code.toLowerCase() === 'mx')?.id);

watch(
    () => form.country_id,
    () => {
        if (!countryIsMexico.value) {
            form.state_id = null;
        }
    },
);

onBeforeMount(() => {
    fetchStates();
    fetchCountries();
});
</script>
