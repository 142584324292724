export type HasError = Ref<boolean>;
type ComponentRef = Ref<HasError>;

export function useInjectErrorState(): HasError {
    const hasErrorDialogRef = inject<ComponentRef>('hasErrorDialogRef');
    const hasError = ref(false);
    onMounted(() => {
        if (hasErrorDialogRef) {
            hasErrorDialogRef.value = hasError;
        }
    });

    return hasError;
}

export function useProvideErrorState(): ComputedRef<boolean> {
    // If you see this error or something like it, one hasErrorDialogRef, it is a false positive.
    // Vue: Type Ref<boolean> is not assignable to type HasErrorRef
    // Type boolean is not assignable to type HasError
    // We hack fix it for know by casting it to unknown and then to ComponentRef
    const hasErrorDialogRef: ComponentRef = ref<HasError>(ref<boolean>(false)) as unknown as ComponentRef;
    provide<ComponentRef>('hasErrorDialogRef', hasErrorDialogRef);

    return computed(() => {
        return hasErrorDialogRef.value.value;
    });
}
