<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" type="number" label="Area (ha)" :field="'partition_area_ha'" />
            </v-col>
            <v-col cols="12" sm="6">
                <Autocomplete v-model:selected-items="form.landuse_type_id" v-model:form="form" :field-name="'landuse_type_id'" :items="landuseTypes" :loading="fetching" label="Landuse Types" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IPartition } from '@app/land-hold/partition/models/Partition';
import { type ILandUseType, LandUseType } from '@app/land-hold/land-use-type/models/LandUseType';
import type { IProperty } from '@app/land-hold/property/models/Property';
import { useFetchInertiaPageJson } from '@shared/composables/useFetchInertiaPageJson';
import { useRoute } from '@shared/composables/useRoute';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import type { ListResponse, SingleObjectResponse } from '@shared/types/ApiResponse';

const props = defineProps({
    item: {
        type: Object as PropType<IPartition>,
        required: true,
    },
    property: {
        type: Object as PropType<SingleObjectResponse<IProperty>>,
        required: true,
    },
});

const store = usePropertyRepository().command.storePartition({
    ...props.item,
    property_id: props.property.data.id,
});
const form = store.form;
useInjectSubmitForm(store.execute);

const landuseTypes: Ref<ILandUseType[]> = ref([]);
const fetching = ref(false);

function fetchLanduseTypes() {
    fetching.value = true;
    useFetchInertiaPageJson<ListResponse<ILandUseType>>(useRoute().build(LandUseType.routes().index), 'appSection@landUse::IndexLandUseTypesPage', [], {
        params: {
            limit: 0,
        },
    })
        .then(({ data: page }) => {
            landuseTypes.value = (page.props as ListResponse<ILandUseType>).data;
        })
        .catch((err: unknown) => {
            console.error(err);
        })
        .finally(() => {
            fetching.value = false;
        });
}

onBeforeMount(() => {
    fetchLanduseTypes();
});

const emit = defineEmits(['unmounted']);

onUnmounted(() => {
    emit('unmounted');
});
</script>
