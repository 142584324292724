<template>
    <v-form>
        <v-row>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="6">
                <ValidatableTextField v-model:form="form" type="number" label="Buffer Percentage" :field="'buffer_percentage'" />
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IInventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { useInventoryTypeRepository } from '@app/land-hold/inventory-type/repositories/useInventoryTypeRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IInventoryType>,
        required: true,
    },
});

const store = useInventoryTypeRepository().command.store(props.item);
const form = store.form;
useInjectSubmitForm(store.execute);
</script>
