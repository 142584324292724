import type { HasError } from '@shared/composables/useProvideErrorState';
import type { EitherForm, InertiaFormData, FormOptions } from '@shared/types/Form';

export function useWithValidationConfig<T extends InertiaFormData>(hasError: HasError, form: () => EitherForm<T>, options?: FormOptions): FormOptions {
    return {
        onValidationError: () => {
            hasError.value = form().hasErrors;
        },
        onPrecognitionSuccess: () => {
            hasError.value = form().hasErrors;
        },
        onError: () => {
            hasError.value = form().hasErrors;
        },
        ...options,
    };
}
