<template>
    <DataTable
        title="Inventory Types"
        :headers="InventoryType.headers()"
        :model-class="InventoryType"
        :items="page.props.data"
        :crud-config="{
            createDialogBtnText: 'Create Inventory Type',
            updateDialogBtnText: 'Update Inventory Type',
            deleteDialogBtnText: 'Delete Inventory Type',
        }"
        :config="useConfig().inventoryType"
    >
        <template #create-action-dialog="{ item }">
            <CreateInventoryTypeDialog :item />
        </template>
        <template #update-action-dialog="{ item }">
            <UpdateInventoryTypeDialog :item />
        </template>
        <template #delete-action-dialog="{ item }">
            <DeleteInventoryTypeDialog :item />
        </template>
    </DataTable>
</template>

<script setup lang="ts">
import { useConfig } from '@shared/composables/useConfig';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import type { PaginatedResponse } from '@shared/types/ApiResponse';

const page = usePage<PaginatedResponse<IInventoryType>>();
</script>
