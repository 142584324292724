import { Model } from '@shared/models/Model';
import { type ICountry } from '@app/location/country/models/Country';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';

import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { SingleObjectInclude } from '@shared/types/ApiResponse';
import type { IModel } from '@shared/types/Model';

export interface IState extends IModel {
    airtable_id: string;
    _3_letter_code: string;
    name: string;
    country_id: string;
    country: SingleObjectInclude<ICountry>;
}

export class State extends Model implements IState {
    public airtable_id!: string;
    public _3_letter_code!: string;
    public name!: string;
    public country_id!: string;
    public country!: SingleObjectInclude<ICountry>;

    public constructor(o: IState) {
        super(o);
        Object.assign(this, o);
    }

    public hidden(): (keyof IState)[] {
        return ['country'];
    }

    public static override headers(): DataTableHeaders<IState> {
        return [
            { title: 'Actions', key: 'actions', sortable: false, fixed: true, width: 113, minWidth: '113', headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } } },
            { title: '3-letter Code', key: '_3_letter_code' },
            { title: 'Name', key: 'name' },
            { title: 'Country', key: 'country.data.name' },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'states.index', IState>;
        show: ShowRoute<'states.show', IState>;
        destroy: DestroyRoute<'states.destroy'>;
        create: CreateRoute<'states.create'>;
        store: StoreRoute<'states.store'>;
        edit: EditRoute<'states.edit'>;
        update: UpdateRoute<'states.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'states.index',
                params: {
                    orderBy: 'name',
                    sortedBy: 'desc',
                },
                datatableHeaders: () => State.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'states.show', page: usePage() },
            destroy: { method: 'delete', name: 'states.destroy', page: usePage() },
            create: { method: 'get', name: 'states.create', page: usePage() },
            store: { method: 'post', name: 'states.store', page: usePage() },
            edit: { method: 'get', name: 'states.edit', page: usePage() },
            update: { method: 'patch', name: 'states.update', page: usePage() },
        };
    }
}
