<template>
    <v-app-bar color="primary" density="default">
        <!--        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" />-->
        <v-list-item prepend-avatar="@shared/assets/images/gesg-logo.png" lines="two" title="Carbono Biodiverso" subtitle="Public Registry" />
        <v-spacer />
    </v-app-bar>
    <!--    <v-navigation-drawer v-model="drawer" :color="theme.global.current.value.variables['sidebarSurface'] as string" :location="$vuetify.display.mobile ? 'bottom' : 'start'">-->
    <!--        <v-list v-model:opened="open" nav>-->
    <!--            <template v-for="(parent, i) in items">-->
    <!--                <v-list-item v-if="isEmptyArray(parent.children)" :key="parent.title + 'parent' + i" :to="parent.to" :prepend-icon="parent.icon" :active="parent.active" :title="parent.title" nav @click="activateItem(parent)" />-->
    <!--                <v-list-group v-else :key="parent.title + 'group'" :value="parent.title">-->
    <!--                    <template #activator="{ props }">-->
    <!--                        <v-list-item v-bind="props" :prepend-icon="parent.icon" :title="parent.title" />-->
    <!--                    </template>-->
    <!--                    <v-list-item v-for="(child, j) in parent.children" :key="child.title + 'child' + j" :to="child.to" :prepend-icon="child.icon" :title="child.title" :value="child.title" :active="child.active" nav @click="activateItem(child)" />-->
    <!--                </v-list-group>-->
    <!--            </template>-->
    <!--        </v-list>-->
    <!--    </v-navigation-drawer>-->
</template>

<script setup lang="ts">
import { useRoute } from '@shared/composables/useRoute';
import { useUtility } from '@shared/composables/useUtility';
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';

const { isEmptyArray } = useUtility();
const theme = useTheme();
const open = ref([]);
const drawer = ref(true);

interface INavItem {
    title: string;
    icon?: string;
    to?: string;
    active: boolean;
}

type ChildNavItem = INavItem;

interface IParentNavItem extends INavItem {
    active: boolean;
    children: ChildNavItem[];
}

function activateItem(item: IParentNavItem | ChildNavItem) {
    items.forEach((parent) => {
        parent.active = parent === item;
        parent.children.forEach((child) => {
            child.active = child === item;
        });
    });
}

const PublicTransactions = reactive<IParentNavItem>({
    title: 'Transactions',
    to: useRoute().build(PublicTransaction.routes().index),
    icon: 'mdi-handshake',
    active: false,
    children: [],
});

const items = [PublicTransactions];
</script>
